import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  PLATFORM_ID,
  Renderer2,
  TransferState,
  ViewChild,
  ViewEncapsulation,
  makeStateKey,
} from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription, interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from '../common_service/http.service';
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import Swal from 'sweetalert2';
import { OptinFormComponent } from '../optin-form/optin-form.component';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { SessionStorageService } from '../session-service.service';
import { ApiService } from '../blog/shared/data-service.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

declare var $: any;

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void; // Define the gtag function type
  }
}



@Component({
  selector: 'app-dynamic-page',
  templateUrl: './dynamic-page.component.html',
  styleUrls: ['./dynamic-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicPageComponent {
  private searchTerms = new Subject<string>();
  public currentSearchTerm: string = '';
  defaultIMG: string = environment.defaultImg;
  notFoundPage: boolean = false;
  private subscription!: Subscription;
  favIcon!: HTMLLinkElement;
  public dateNow = new Date();
  public dDay = new Date(new Date().getFullYear(), 0, 1);
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  latestNews: any = {};
  websiteUrl = environment.WebsiteApiUrl;
  emailUrl = environment.EmailAPIUrl;
  search = false;
  label_input: any;
  public timeDifference: any;
  public secondsToDday: any;
  public minutesToDday: any;
  public hoursToDday: any;
  public daysToDday: any;
  thankyouPageUrl = ["contact-us/thank-you", "submit-artwork/thank-you", "subscription/thank-you"];
  pageLoaded: boolean = false;
  arrowRight: any;
  arrowLeft: any;
  svg_Animation: boolean = false;
  jobDescription: any;
  jobTitle: any;
  job_id: any;
  isBrowser: Boolean = false;
  isServer: Boolean = false;
  isChecked: boolean = true;
  firstName: any;
  LastName: any;
  company_Name: any;
  HowCanWeHelp: any;
  private getTimeDifference() {
    this.allocateTimeUnits();
  }
  footer = false;
  private allocateTimeUnits() {
    var currentDateTime: any;
    currentDateTime = new Date();
    var month = currentDateTime.getMonth() + 1;
    var futureDate: any;
    futureDate = new Date(currentDateTime.getFullYear(), month, 1);
    var year = currentDateTime.getFullYear();

    var seconds = Math.floor((futureDate - currentDateTime) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    let lenTimeritem =
      this.elementRef.nativeElement.querySelectorAll('.timer-item');
    for (var k = 0; k < lenTimeritem.length; k++) {
      let timerClass = lenTimeritem[k].querySelector('.circle');
      let timerType = timerClass.getAttribute('data-behavior');
      if (timerType == 'countdown-timer-seconds') {
        timerClass.innerHTML = seconds;
      } else if (timerType == 'countdown-timer-days') {
        timerClass.innerHTML = days;
      } else if (timerType == 'countdown-timer-minutes') {
        timerClass.innerHTML = minutes;
      } else if (timerType == 'countdown-timer-hours') {
        timerClass.innerHTML = hours;
      }
    }
  }
  queryParam: any = '';
  @ViewChild('btn') toggleButton: ElementRef | undefined;
  domain: any = '';
  domainInfo: any = {};
  mainDivhideShow: boolean = false;
  currentPageId: any;
  email: any = '';
  dynamicContent: any = '';
  optinFormData: any = {};
  pageInfo: any = {};
  menu: any = [];
  navbarCollapsed = true;
  webId: any = '';
  slug: any = '';
  openPopUp: any = false;
  SuppliedName: string = '';
  private industriesSlugArray: string[] = [
    'industries/cruise-charters',
    'industries/hospitality',
    'industries/events',
    'industries/cruise-charters',
    'industries/entertainment',
    'industries/transit-access-control',
    'industries/casino',
    'industries/gift-loyalty',
  ];
  private animationRoutes: string[] = [];
  private animationArray: any = [
    { page: 'fraud-prevention', function: () => this.fraudPreventionAnimation() },
    { page: 'sustainability', function: () => this.sustainablityAnimation() },
    {
      page: 'products/gift-loyalty',
      function: () => this.giftLoyaltyProductsAnimation(),
    },
    {
      page: 'products/hospitality-events',
      function: () => this.hospitalityEventsAnimation(),
    },
    {
      page: 'capabilities/card-technology/personalization-fulfillment',
      function: () => this.fulfillmentAnimation(),
    },
    {
      page: 'capabilities/card-printing-attributes',
      function: () => this.cardPrintingAttributesAnimation(),
    },
    {
      page: 'capabilities/card-marketing-pop-display-distribution/pop-displays',
      function: () => this.displayAnimation(),
    },
    {
      page: 'capabilities/packaging-displays',
      function: () => this.packagingAnimation(),
    },
    {
      page: 'pli-advantage/locations',
      function: () => this.locationAnimation(),
    },
    { page: 'pli-advantage', function: () => this.aboutUsAnimation() },
    {
      page: 'pli-advantage/one-team-one-dream',
      function: () => this.oneTeamOneDreamAnimation(),
    },
    {
      page: 'industries/gift-loyalty',
      function: () => this.industryGiftLoyalty(),
    },
    {
      page: 'markets/hospitality/international-hotels',
      function: () => this.industryHospitality(),
    },
    {
      page: 'markets/events/event-key-cards',
      function: () => this.industryEvents(),
    },
    { page: 'industries/casino', function: () => this.industryCasino() },
    {
      page: 'markets/hospitality/cruise',
      function: () => this.industryCruiseCharter(),
    },
    {
      page: 'industries/entertainment',
      function: () => this.industryEntertainment(),
    },
    {
      page: 'industries/transit-access-control',
      function: () => this.industryTransit(),
    },
  ];
  private animationIndex: number = 0;
  searchResults: any = [];
  clearCoJobs: any;
  blogData: any = [];
  private originalLabelContent: any = '';
  listenerFn: (() => void) | undefined;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private readonly elementRef: ElementRef,
    public dialog: MatDialog,
    protected sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public _service: HttpService,
    private _router: Router,
    private transferState: TransferState,
    private sessionStorageService: SessionStorageService,
    private apiService: ApiService,
    private titleService: Title,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this._service.showFooter.subscribe((x) => {
      if (x != null) {
        this.footer = x;
      }
    });
  }

  searchHandle() {
    this.search = !this.search;
  }

  navigate(page: string) {
    this._router.navigate([page]);
  }

  @HostListener('window:click', ['$event'])
  onClick(e: any) {
    const path = e.composedPath() as Array<any>;
    const firstAnchor = path.find((p) =>
      p.tagename ? p.tagName.toLowerCase() === 'a' : ''
    );

    if (firstAnchor && !firstAnchor.hasAttribute('routerlink')) {
      const href = firstAnchor.getAttribute('href');
      if (href) {
        let lastIndex = href.lastIndexOf('/');
        // alert(href)
        let route = href.slice(lastIndex + 1);
        // this.navTo(route);
        e.preventDefault();
      }
    }
  }
  ngOnInit() {
    if (
      this._router?.url?.toLowerCase() === '/billpay' &&
      isPlatformBrowser(this.platformId)
    ) {
      window.location.href =
        'https://www.payerexpress.com/ebp/PLIQP/Login/Index';
    } else {
      this.domain = 'theecodecrm';
      this.isBrowser = isPlatformBrowser(this.platformId);
      this.isServer = isPlatformServer(this.platformId);
      this.searchTerms
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe((term) => {
          this.currentSearchTerm = term;
          this.onSearch(term);
        });
      if (this._service.isDesktopDevice()) {
        this.mainDivhideShow = true;
      }

      let api = `${this.websiteUrl}/api/Website/get?Domain=${this.domain}&LoadCompanyInfo=true`;
      this._service.get(api).subscribe((el: any) => {
        this.webId = el.website_id;
        this.sessionStorageService.setItem('loginDetail', JSON.stringify(el));
        this.sessionStorageService.setItem(
          'website_id',
          JSON.stringify(el?.website_id)
        );
        this.sessionStorageService.setItem(
          'company_id',
          JSON.stringify(el?.company_id)
        );
        if (isPlatformServer(this.platformId)) {
          const key = makeStateKey<string>('webid');
          this.transferState.set(key, el.website_id);
          // this.getlatestnews(el?.company_id)

          // Update the favicon dynamically

          // Update the browser title dynamically
        }

        if (isPlatformBrowser(this.platformId)) {
          // this.removeConversionScript();
          const key = makeStateKey<string>('webid');
          const data = this.transferState.get(key, null);
          localStorage.setItem('webid', el.website_id);
          this.updateFavicon(el?.company_info?.fav_logo);
        }
        this.domainInfo = el;
        this._service.sharedVariable = el;
        el.menu.sort(function (a: any, b: any) {
          return a.sequence - b.sequence;
        });
        this.sessionStorageService.setMenu(el.menu);
        this.menu = el.menu;
        const script = this.renderer.createElement('script');
        const fbscript = this.renderer.createElement('script');
        if (el.company_info.facebook_pixel_script) {
          fbscript.text = `${el.company_info.facebook_pixel_script}`;
          this.renderer.appendChild(this.elementRef.nativeElement, fbscript);
        }
        if (el.company_info.tidio_script) {
          script.src = `${el.company_info.tidio_script}`;
          this.renderer.appendChild(this.elementRef.nativeElement, script);
        }
        if (this.dynamicContent.length) {
          for (let i = 0; i < this.dynamicContent.length; i++) {
            this.dynamicContent[i].template_html = this.replaceTags(
              this.dynamicContent[i].template_html
            );
          }
          setTimeout(() => {
            this.removeEmptyElements();
          }, 0);
        }
      });

      this.route.url.subscribe((segments) => {
        const urlParts = segments.map((segment) => segment.path);
        const slug = urlParts.join('/');
        this.slug = slug.toLowerCase();

        // SEO new URL changes and redirection
        if (this.slug === 'capabilites/card-printing-attributes') {
          this.navTo('capabilities/card-printing-attributes');
        } else if (this.slug === 'capabilites/packaging-displays') {
          this.navTo('capabilities/packaging-displays');
        }
        console.log(this.slug);
        this.animationArray.filter((ele: any, idx: number) => {
          this.animationRoutes.push(ele.page);
          if (ele.page == this.slug) {
            this.animationIndex = idx;
          }
        });
        if (
          this.slug != 'capabilites/card-printing-attributes' &&
          this.slug != 'capabilites/packaging-displays'
        ) {
          this.getPageList();

        }
      });
    }
  }





  homePageIndustriesAnimation() {
    $(document).ready(() => {
      var sync1 = $('#sync1');
      var sync2 = $('#sync2');
      var slidesPerPage = 7; //globaly define number of elements per page
      var syncedSecondary = true;

      sync1
        .owlCarousel({
          items: 1,
          slideSpeed: 2000,
          nav: true,
          // autoplay: true,
          dots: true,
          loop: true,
          responsiveRefreshRate: 200,
          navText: [
            '<img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/left_arrow.svg">',
            '<img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/righarrow_icon.svg">',
          ],
        })
        .on('changed.owl.carousel', syncPosition);

      sync2
        .on('initialized.owl.carousel', () => {
          sync2.find('.owl-item').eq(0).addClass('current');
        })
        .owlCarousel({
          items: slidesPerPage,
          dots: true,
          nav: true,
          margin: 10,
          smartSpeed: 200,
          slideSpeed: 500,
          slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
          responsiveRefreshRate: 100,
        })
        .on('changed.owl.carousel', syncPosition2);

      function syncPosition(el: any) {
        //if you set loop to false, you have to restore this next line
        //var current = el.item.index;

        //if you disable loop you have to comment this block
        var count = el.item.count - 1;
        var current = Math.round(el.item.index - el.item.count / 2 - 0.5);

        if (current < 0) {
          current = count;
        }
        if (current > count) {
          current = 0;
        }

        //end block

        sync2
          .find('.owl-item')
          .removeClass('current')
          .eq(current)
          .addClass('current');
        var onscreen = sync2.find('.owl-item.active').length - 1;
        var start = sync2.find('.owl-item.active').first().index();
        var end = sync2.find('.owl-item.active').last().index();

        if (current > end) {
          sync2.data('owl.carousel').to(current, 100, true);
        }
        if (current < start) {
          sync2.data('owl.carousel').to(current - onscreen, 100, true);
        }
      }

      function syncPosition2(el: any) {
        if (syncedSecondary) {
          var number = el.item.index;
          sync1.data('owl.carousel').to(number, 100, true);
        }
      }

      sync2.on('click', '.owl-item', (e: any) => {
        e.preventDefault();

        var number = $(e.currentTarget).index();
        sync1.data('owl.carousel').to(number, 300, true);
      });
    });
  }

  getlatestnews(id: number) {
    this.apiService.getAllNewsHome(id).subscribe({
      next: (data: any) => {
        this.latestNews = data?.news?.[0] || {};
      },
      error: (error) => {
        console.log('error', error);
      },
    });
  }
  navNews(news: any) {
    if (news?.news_id) {
      if (news?.category === 'BLOG') {
        this._router.navigate([`card-talk/${news?.slug}`]);
      } else {
        this._router.navigate([`news/detail/${news?.news_id}`]);
      }
    }
  }

  homeAnimation() {
    if (isPlatformBrowser(this.platformId)) {
      gsap.registerPlugin(
        ScrollTrigger,
        // ScrollToPlugin,
        ScrollSmoother,
        MotionPathPlugin
      );

      let windowWidth = window.innerWidth;
      let cardAnimation = gsap.timeline();

      // Responsive
      const mm = gsap.matchMedia();

      let custom_svg = this.elementRef.nativeElement.querySelector(
        '.custom-svg'
      ) as HTMLInputElement;
      if (custom_svg) {
        custom_svg.style.paddingTop = `${80}px`;
      }
      let ballpath = this.elementRef.nativeElement.querySelector(
        '.ballpath-svg'
      ) as HTMLInputElement;
      if (ballpath) {
        ballpath.style.paddingTop = `${80}px`;
      }

      mm.add('(min-width: 1027px) and (max-width: 1229px)', () => {
        let width = (screen.width - 1027) / 2.4;
        let newPath1 =
          'M48.883,251.287C222.235,415.021,222.82,470.094,561.886,442.102';
        let newPath =
          'M716.182,436.793C713.783,483.531,735.708,573.856,738.179,616.63,742.756,696.053,737.785,725.533,711.492,835.399,661.165,1045.66,520.01,975.407,477.275,1224.47,447.573,1397.442,467.054,1444.835,474.066,1610.86,475.328,1641.139,470.61,1920.918,482.254,2347.569,483.23,2383.694,481.292,2385.006,484.257,2419.631,488.786,2473.348,533.037,2445.342,805.576,2444.623';

        let dash_impact = this.elementRef.nativeElement.querySelector(
          '.dash_impact'
        ) as HTMLInputElement;
        if (dash_impact) {
          dash_impact.style.right = `${width - width * 0.5}px`;
        }
        let custom_svg = this.elementRef.nativeElement.querySelector(
          '.custom-svg'
        ) as HTMLInputElement;
        if (custom_svg) {
          custom_svg.style.paddingLeft = `${width + width * 0.5}px`;
        }
        let ballpath = this.elementRef.nativeElement.querySelector(
          '.ballpath-svg'
        ) as HTMLInputElement;
        if (ballpath) {
          ballpath.style.paddingLeft = `${width + width * 1}px`;
        }
        let custom_path1 =
          this.elementRef.nativeElement.querySelector('.custom-path1');
        if (custom_path1) {
          custom_path1.setAttribute('d', newPath1);
        }
        let custom_path =
          this.elementRef.nativeElement.querySelector('.custom-path');
        if (custom_path) {
          custom_path.setAttribute('d', newPath);
        }
      });

      mm.add('(min-width: 1396px) and (max-width: 1516px)', () => {
        let width = (screen.width - 1396) / 2.4;
        let newPath =
          'M963.856,482.457C968.438,531.824,974.607,539.564,977.078,582.331,981.66,661.755,968.588,760.135,942.295,869.999,891.968,1080.266,387.075,1008.156,344.332,1257.205,314.644,1430.183,684.29,1649.283,691.29,1815.303,692.564,1845.582,694.314,2310.307,701.675,2719.897,702.324,2756.03,702.527,2838.939,705.469,2873.564,710.018,2927.298,791.419,2915.838,1061.46,2914.059';

        let custom_svg = this.elementRef.nativeElement.querySelector(
          '.custom-svg'
        ) as HTMLInputElement;
        if (custom_svg) {
          custom_svg.style.paddingLeft = `${width}px`;
        }
        let ballpath_svg = this.elementRef.nativeElement.querySelector(
          '.ballpath-svg'
        ) as HTMLInputElement;
        if (ballpath_svg) {
          ballpath_svg.style.paddingLeft = `${width + 60}px`;
        }
        let custom_path =
          this.elementRef.nativeElement.querySelector('.custom-path');
        if (custom_path) {
          custom_path.setAttribute('d', newPath);
        }
      });

      mm.add('(min-width: 1517px) and (max-width: 1706px)', () => {
        let width = (screen.width - 1517) / 2.4;
        let newPath =
          'M1013.849,465.21C1018.426,514.568,1026.327,548.18,1028.8,590.949,1033.379,670.372,1008.237,768.754,981.949,878.619,931.618,1088.886,387.075,1008.156,344.332,1257.205,314.644,1430.183,727.393,1656.177,734.393,1822.198,735.663,1852.477,723.619,2312.031,730.984,2721.621,731.633,2757.754,731.831,2861.351,734.777,2895.976,739.32,2949.709,877.592,2931.35,1147.661,2929.571';

        let custom_svg = this.elementRef.nativeElement.querySelector(
          '.custom-svg'
        ) as HTMLInputElement;
        if (custom_svg) {
          custom_svg.style.paddingLeft = `${width}px`;
        }
        let ballpath_svg = this.elementRef.nativeElement.querySelector(
          '.ballpath-svg'
        ) as HTMLInputElement;
        if (ballpath_svg) {
          ballpath_svg.style.paddingLeft = `${width + 120}px`;
        }
        let custom_path =
          this.elementRef.nativeElement.querySelector('.custom-path');
        if (custom_path) {
          custom_path.setAttribute('d', newPath);
        }
      });

      mm.add('(min-width: 1707px) and (max-width: 1820px)', () => {
        let width = (screen.width - 1707) / 2.4;
        let newPath =
          'M1093.848,463.208C1098.425,512.566,1102.327,542.178,1104.8,584.947,1109.377,664.37,1088.234,796.754,1061.943,906.619,1011.616,1116.886,381.075,1008.155,338.332,1257.204,308.644,1430.182,803.393,1686.175,810.393,1852.197,811.663,1882.476,801.619,2324.031,808.984,2733.621,809.633,2769.754,807.829,2811.351,810.776,2845.976,815.319,2899.709,931.592,2883.35,1201.661,2881.571';

        let custom_svg = this.elementRef.nativeElement.querySelector(
          '.custom-svg'
        ) as HTMLInputElement;
        if (custom_svg) {
          custom_svg.style.paddingLeft = `${width + 10}px`;
        }
        let ballpath_svg = this.elementRef.nativeElement.querySelector(
          '.ballpath-svg'
        ) as HTMLInputElement;
        if (ballpath_svg) {
          ballpath_svg.style.paddingLeft = `${width + 180}px`;
        }
        let custom_path =
          this.elementRef.nativeElement.querySelector('.custom-path');
        if (custom_path) {
          custom_path.setAttribute('d', newPath);
        }
      });

      mm.add('(min-width: 1821px)', () => {
        let width = (screen.width - 1707) / 2.4;
        let newPath =
          'M1095.864,456.429C1100.447,505.794,1115.155,553.861,1117.635,596.63,1122.213,676.053,1112.036,827.998,1085.744,937.863,1035.418,1148.13,387.075,1008.156,344.332,1257.205,314.644,1430.183,841.511,1649.067,848.511,1815.086,849.785,1845.365,826.752,2248.261,834.118,2657.848,834.758,2693.986,840.905,2790.562,843.852,2825.187,848.396,2878.928,974.069,2862.166,1246.584,2861.436';

        let custom_svg = this.elementRef.nativeElement.querySelector(
          '.custom-svg'
        ) as HTMLInputElement;
        if (custom_svg) {
          custom_svg.style.paddingLeft = `${width + 10}px`;
        }
        let ballpath_svg = this.elementRef.nativeElement.querySelector(
          '.ballpath-svg'
        ) as HTMLInputElement;
        if (ballpath_svg) {
          ballpath_svg.style.paddingLeft = `${width + 200}px`;
        }
        let custom_path =
          this.elementRef.nativeElement.querySelector('.custom-path');
        if (custom_path) {
          custom_path.setAttribute('d', newPath);
        }
      });

      const carousel = async () => {
        const elements = document.querySelectorAll('.wrapper');
        if (elements) {
          const wrapper =
            this.elementRef.nativeElement.querySelector('.wrapper');
          const trigggerElement = this.elementRef.nativeElement.querySelector(
            '.industries_we_specialize'
          );
          const panels = gsap.utils.toArray('.wrapper .customSlide');
          const navLis = document.querySelectorAll('.carousel_indicator li a');

          await elements.forEach((element, i) => {
            var elementsWrapper = element.getAttribute('id');
            const sections = gsap.utils.toArray(
              '#' + elementsWrapper + ' .customSlide'
            );
            const numSections = sections.length - 1;
            const snapVal = 1 / numSections;
            let lastIndex = 0;
            let dif = 0;

            let tween = gsap.to(sections, {
              xPercent: -100 * numSections,
              ease: 'none',
              scrollTrigger: {
                trigger: trigggerElement,
                start: '+=300',
                pin: true,
                scrub: 1,
                snap: {
                  snapTo: 1 / (sections.length - 1),
                  inertia: false,
                  duration: { min: 0.4, max: 0.4 },
                },
                onUpdate: async (self) => {
                  const newIndex = Math.round(self.progress / snapVal);
                  if (newIndex !== lastIndex) {
                    await navLis[lastIndex].classList.remove('active');
                    await navLis[newIndex].classList.add('active');
                    lastIndex = newIndex;
                  }
                },
              },
            });

            navLis.forEach((anchor, i) => {
              anchor.addEventListener('click', function (e: any) {
                e.preventDefault();
                let targetElem = document.querySelector(
                  e.target.getAttribute('href')
                );
                let y = targetElem;

                if (
                  targetElem &&
                  wrapper.isSameNode(targetElem.parentElement) &&
                  tween.scrollTrigger
                ) {
                  let totalScroll =
                    tween.scrollTrigger.end - tween.scrollTrigger.start,
                    totalMovement =
                      (panels.length - 1) * targetElem.offsetWidth;
                  y = Math.round(
                    tween.scrollTrigger.start +
                    (targetElem.offsetLeft / totalMovement) * totalScroll
                  );
                  if (i == 0) {
                    dif = y - tween.scrollTrigger.start;
                  }
                }

                gsap.to(window, {
                  scrollTo: {
                    y:
                      i == 0
                        ? tween.scrollTrigger
                          ? tween.scrollTrigger.start
                          : 0
                        : y - dif,
                    autoKill: false,
                  },
                  duration: 0.8,
                });
              });
            });
          });
        }
      };

      const carouselOne = async () => {
        const carouselTimeLine = await gsap.timeline();
        const carouselOnePanel = gsap.utils.toArray('.capabilitiesRow');
        if (carouselOnePanel) {
          await gsap.to('.capabilitiesItem', {
            x: -100,
            stagger: 0.3,
            duration: 2,
            force3D: true,
            yoyo: true,
            repeat: -1,
          });

          await cardAnimation.fromTo(
            carouselOnePanel,
            {
              x: -100,
              ease: 'none',
              scrollTrigger: {
                trigger: '.pli_capabilities .container',
                // pin: true,
                scrub: 1,
                toggleActions: 'pause pause pause reverse',
              },
            },
            {
              x: 10,
              ease: 'none',
              scrollTrigger: {
                trigger: '.pli_capabilities .container',
                // pin: true,
                scrub: 1,
                toggleActions: 'pause pause pause reverse',
              },
            }
          );
        }
      };

      //Mobile Screen
      mm.add('(min-width: 1026px)', () => {
        let cards = document.querySelectorAll('.cardmotion');
        if (cards.length) {
          let ballCount = 0;
          let checkScale = false;
          let ballAnimation = gsap.timeline();
          // smoother.effects(cards, { speed: "auto" });

          setTimeout(async () => {
            await ballDash();
            await circularAnimation();
          }, 100);

          const ballDash = async () => {
            let ballDash = gsap.to('.artboardAnimation', {
              duration: 5,
              onUpdate: function () {
                if (ballDash.progress() > 0.4) {
                  ++ballCount;
                  if (ballCount == 1) {
                    ballImpact();
                    scrollCard();
                  }
                }
              },
              motionPath: {
                path: '.custom-path1',
                align: '.custom-path1',
                alignOrigin: [1, 0.5],
                autoRotate: 360,
                // scrub: 1,
              },
              ease: 'bounce.out',
            });
          };

          const circularAnimation = async () => {
            let titleAnimation = gsap.timeline();
            await titleAnimation
              .to('.advantageBig_Circle', {
                scrollTrigger: {
                  trigger: '.pli_Advantages',
                  start: () => 'top top',
                  end: () => 'bottom top',
                  // pin: true,
                  toggleActions: 'pause pause pause reverse',
                },
                y: -50,
                scale: 0.8,
              })
              .fromTo(
                '#adv_one',
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  scale: 0,
                  opacity: 0.7,
                  x: 1140,
                },
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  opacity: 1,
                  scale: 0.85,
                  y: -225,
                  x: 1460,
                },
                0
              )
              .fromTo(
                '#adv_two',
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  scale: 0,
                  opacity: 0.7,
                  x: 860,
                  // y: -80,
                },
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  opacity: 1,
                  scale: 0.85,
                  y: -125,
                  x: 480,
                },
                0
              )
              .fromTo(
                '#adv_three',
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  opacity: 0.7,
                  scale: 0,
                  x: 600,
                },
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  scale: 0.85,
                  opacity: 1,
                  y: -250,
                  x: 290,
                },
                0
              )
              .fromTo(
                '#adv_four',
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  scale: 0,
                  opacity: 0.7,
                  x: 300,
                },
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  scale: 0.85,
                  opacity: 1,
                  y: 70,
                  x: 680,
                },
                0
              )
              .fromTo(
                '#adv_five',
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  opacity: 0.7,
                  scale: 0,
                  x: 0,
                },
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  opacity: 1,
                  scale: 0.85,
                  y: 0,
                  x: -400,
                },
                0
              )
              .fromTo(
                '#adv_six',
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  opacity: 0.7,
                  scale: 0,
                  x: -300,
                },
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  opacity: 1,
                  scale: 0.85,
                  y: -70,
                  x: 100,
                },
                0
              )
              .fromTo(
                '#adv_seven',
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  opacity: 0.7,
                  scale: 0,
                  x: -550,
                },
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  opacity: 1,
                  scale: 0.85,
                  y: 255,
                  x: -840,
                },
                0
              )
              .fromTo(
                '#adv_eight',
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  opacity: 0.7,
                  scale: 0,
                  x: -830,
                },
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  opacity: 1,
                  scale: 0.85,
                  y: 130,
                  x: -1230,
                },
                0
              )
              .fromTo(
                '#adv_nine',
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  opacity: 0.7,
                  scale: 0,
                  x: -1130,
                },
                {
                  scrollTrigger: {
                    trigger: '.pli_Advantages',
                    scrub: 0.8,
                    toggleActions: 'pause pause pause reverse',
                  },
                  opacity: 1,
                  scale: 0.85,
                  y: 220,
                  x: -820,
                },
                0
              );
          };

          //card animation

          const scrollCard = async () => {
            let autoRotate = -90;
            let scaleX = 0.7;
            ballAnimation.to('.cardOne', {
              scrollTrigger: {
                scrub: 0.8,
                end: '+=2600',
                pin: true,
                toggleActions: 'pause pause pause pause',
              },
              motionPath: {
                path: '.custom-path',
                align: '.custom-path',
                alignOrigin: [0.5, 0.5],
                autoRotate: autoRotate,
              },
              onComplete: () => {
                gsap.fromTo(
                  '.cardOne',
                  { scale: 0, opacity: 0 },
                  { scale: 1, opacity: 1 }
                );
              },
              scale: scaleX,
              ease: 'none',
            });
          };
          //Ball Animation
          const ballImpact = async () => {
            let yAxis = (screen.width / 100 + 10) * -1;
            await gsap.to('.dash_impact', {
              scale: 1,
              opacity: 1,
              duration: 0.5,
              x: -5,
              y: yAxis,
            });
          };

          gsap.from('.graph_count_country', {
            scrollTrigger: '.StepFrame',
            innerText: 0,
            duration: 4,
            snap: {
              innerText: 4,
            },
          });

          gsap.from('.graph_count', {
            scrollTrigger: '.StepFrame',
            innerText: 0,
            duration: 2,
            snap: {
              innerText: 1,
            },
          });

          const carouselOne = async () => {
            const carouselTimeLine = await gsap.timeline();
            const carouselOnePanel = gsap.utils.toArray('.capabilitiesRow');
            if (carouselOnePanel) {
              await gsap.to('.capabilitiesItem', {
                x: -100,
                stagger: 0.3,
                duration: 2,
                force3D: true,
                yoyo: true,
                repeat: -1,
              });

              await cardAnimation.fromTo(
                carouselOnePanel,
                {
                  x: -100,
                  ease: 'none',
                  scrollTrigger: {
                    trigger: '.pli_capabilities .container',
                    // pin: true,
                    scrub: 1,
                    toggleActions: 'pause pause pause reverse',
                  },
                },
                {
                  x: 10,
                  ease: 'none',
                  scrollTrigger: {
                    trigger: '.pli_capabilities .container',
                    // pin: true,
                    scrub: 1,
                    toggleActions: 'pause pause pause reverse',
                  },
                }
              );
            }
          };

          // carouselOne();
        }
      });
    }
  }

  removeEmptyElements() {
    if (
      this.elementRef.nativeElement.querySelector('.company-connect-email') !=
      null &&
      !this.domainInfo.company_info.customer_email
    ) {
      this.elementRef.nativeElement.querySelector(
        '.company-connect-email'
      ).style.display = 'none';
    }

    if (
      this.elementRef.nativeElement.querySelector('.company-connect-text') !=
      null &&
      !this.domainInfo.company_info.text_number_for_customers
    ) {
      this.elementRef.nativeElement.querySelector(
        '.company-connect-text'
      ).style.display = 'none';
    }

    if (
      this.elementRef.nativeElement.querySelector('.company-connect-phone') !=
      null &&
      !this.domainInfo.company_info.phone_number_for_customers
    ) {
      this.elementRef.nativeElement.querySelector(
        '.company-connect-phone'
      ).style.display = 'none';
    }

    if (
      this.elementRef.nativeElement.querySelector("a[href='#{{linkedin}}']") !=
      null &&
      !this.domainInfo.company_info.social_links.linkedin
    ) {
      this.elementRef.nativeElement.querySelector(
        "a[href='#{{linkedin}}']"
      ).style.display = 'none';
    }

    if (
      this.elementRef.nativeElement.querySelector("a[href='#{{pinterest}}']") !=
      null &&
      !this.domainInfo.company_info.social_links.pinterest
    ) {
      this.elementRef.nativeElement.querySelector(
        "a[href='#{{pinterest}}']"
      ).style.display = 'none';
    }

    if (
      this.elementRef.nativeElement.querySelector("a[href='#{{instagram}}']") !=
      null &&
      !this.domainInfo.company_info.social_links.instagram
    ) {
      this.elementRef.nativeElement.querySelector(
        "a[href='#{{instagram}}']"
      ).style.display = 'none';
    }

    if (
      this.elementRef.nativeElement.querySelector("a[href='#{{facebook}}']") !=
      null &&
      !this.domainInfo.company_info.social_links.facebook
    ) {
      this.elementRef.nativeElement.querySelector(
        "a[href='#{{facebook}}']"
      ).style.display = 'none';
    }
    let replicateUrlClass = this.elementRef.nativeElement.querySelectorAll(
      '.doterra-replicated-url'
    );
    for (let k = 0; k < replicateUrlClass.length; k++) {
      if (
        replicateUrlClass[k] != null &&
        this.domainInfo.company_info.replicated_website_url != null &&
        this.domainInfo.company_info.replicated_website_url != '' &&
        this.domainInfo.company_info.replicated_website_url != null
      ) {
        replicateUrlClass[k].href =
          this.domainInfo.company_info.replicated_website_url;
      } else {
        replicateUrlClass[k].href = null;
        // this.elementRef.nativeElement.querySelector(".doterra-replicated-url").href = "https://www.google.com";
      }
    }
  }

  replaceTags(value: any) {
    if (!this.domainInfo?.company_info) return;
    // EMAIL
    if (this.domainInfo?.company_info?.customer_email) {
      value = value.replace(
        '{{email}}',
        this.domainInfo?.company_info?.customer_email
      );
    }

    // TEXT
    if (this.domainInfo?.company_info?.text_number_for_customers) {
      value = value.replace(
        '{{text}}',
        this.domainInfo?.company_info?.text_number_for_customers
      );
    }

    // PHONE
    if (this.domainInfo?.company_info?.phone_number_for_customers) {
      value = value.replace(
        '{{phone}}',
        this.domainInfo?.company_info.phone_number_for_customers
      );
    }

    // Follow me
    if (this.domainInfo?.company_info?.follow_me_cta) {
      value = value.replace(
        '{{follow-me}}',
        this.domainInfo?.company_info?.follow_me_cta
      );
    } else {
      value = value.replace(
        '{{follow-me}}',
        'Join me in my quest to make everyday healthy habits simple, fun and transformational in our everyday lives!'
      );
    }

    // SOCIAL MEDIA

    if (this.domainInfo?.company_info?.social_links) {
      if (this.domainInfo?.company_info?.social_links?.facebook) {
        value = value.replace(
          '#{{facebook}}',
          this.domainInfo?.company_info?.social_links?.facebook
        );
      }

      if (this.domainInfo?.company_info?.social_links?.instagram) {
        value = value.replace(
          '#{{instagram}}',
          this.domainInfo?.company_info?.social_links?.instagram
        );
      }

      if (this.domainInfo?.company_info?.social_links?.pinterest) {
        value = value.replace(
          '#{{pinterest}}',
          this.domainInfo?.company_info?.social_links?.pinterest
        );
      }

      if (this.domainInfo?.company_info?.social_links?.linkedin) {
        value = value.replace(
          '#{{linkedin}}',
          this.domainInfo?.company_info?.social_links?.linkedin
        );
      }
    }

    if (this.domainInfo?.company_info?.address?.city) {
      value = value.replace(
        '{{address}}',
        this.domainInfo?.company_info?.address?.city
      );
    }

    if (this.domainInfo?.user_info?.first_name) {
      value = value.replace(
        '{{first_name}}',
        this.domainInfo?.user_info?.first_name
      );
    }

    if (this.domainInfo?.user_info?.last_name) {
      value = value.replace(
        '{{last_name}}',
        this.domainInfo?.user_info?.last_name
      );
    }
    return value;
  }
  slugNameEmpty() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.location.hostname.indexOf(environment.domainName) != -1) {
        this.domain = window.location.hostname.split('.')[0];
      } else {
        this.domain = window.location.hostname;
      }
    }
    // this.domain = "arunr";

    let api = `${this.websiteUrl}/api/Website/get?Domain=${this.domain}&LoadCompanyInfo=true`;
    this._service.get(api).subscribe((el: any) => {
      this.webId = el.website_id;
      if (isPlatformServer(this.platformId)) {
        const key = makeStateKey<string>('webid');
        this.transferState.set(key, el.website_id);
      }
      if (isPlatformBrowser(this.platformId)) {
        const key = makeStateKey<string>('webid');
        const data = this.transferState.get(key, null);
        localStorage.setItem('webid', el.website_id);
      }
      // this.titleService.setTitle((el.default_page.title != null && el.default_page.title && el.default_page.title != 'null')?el.default_page.title.toUpperCase() : (this.slug != null && this.slug)?this.slug.toUpperCase():'No Title');

      if (!this.slug) {
        this.dynamicContent = JSON.parse(el.default_page.page_data);
      }
      this.domainInfo = el;
      this._service.sharedVariable = el;
      el.menu.sort(function (a: any, b: any) {
        return a.sequence - b.sequence;
      });
      this.menu = el.menu;
      this.sessionStorageService.setMenu(el.menu);
      const script = this.renderer.createElement('script');
      const fbscript = this.renderer.createElement('script');
      if (el.company_info.facebook_pixel_script) {
        fbscript.text = `${el.company_info.facebook_pixel_script}`;
        this.renderer.appendChild(this.elementRef.nativeElement, fbscript);
      }
      if (el.company_info.tidio_script) {
        script.src = `${el.company_info.tidio_script}`;
        this.renderer.appendChild(this.elementRef.nativeElement, script);
      }
      if (this.dynamicContent.length) {
        for (let i = 0; i < this.dynamicContent.length; i++) {
          this.dynamicContent[i].template_html = this.replaceTags(
            this.dynamicContent[i].template_html
          );
        }
        setTimeout(() => {
          this.removeEmptyElements();
        }, 0);
      }

    });
  }
  scrollToVideo() {
    const videoSection =
      this.elementRef.nativeElement.querySelector('#videoSection');
    if (videoSection) {
      videoSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  //Download PDF Function
  downloadPdf(pdfUrl: string): void {
    const pdfLink = document.createElement('a');
    pdfLink.href = pdfUrl;
    pdfLink.target = '_blank';
    pdfLink.click();
  }

  getPageList(existCls: string = '') {
    this.svg_Animation = false;
    let api = `${this.websiteUrl}/api/Website/pagebyslug?Slug=${this.slug && this.slug != '' ? this.slug : 'Home'
      }&Domain=${this.domain}`;
    this._service.get(api).subscribe({
      next: (el: any) => {
        this._service.hideAndShoeFooter(true);

        this.pageInfo = el;
        if (el?.page?.title) {
          this.titleService.setTitle(`${el?.page?.title}`);
          this._service.updateOgTitle(`${el?.page?.title}`);
          this._service.updateTwitterTitle(`${el?.page?.title}`);
        }
        this._service.updateDescription(el?.page?.metadata ?? '');
        this._service.updateOgUrl();
        this._service.updateOgDescription(el?.page?.metadata ?? '');
        this._service.updateOgImage(el, false);
        this._service.updateTwitterCard('summary_large_image');
        this._service.updateTwitterUrl();
        this._service.updateTwitterDescription(el?.page?.metadata ?? '');
        this._service.updateTwitterImage(el, false);
        this.dynamicContent = JSON.parse(el.page.page_data);
        this.notFoundPage = false;
        this.cdr.detectChanges();
        if (this.domainInfo.company_info) {
          for (let i = 0; i < this.dynamicContent.length; i++) {
            this.dynamicContent[i].template_html = this.replaceTags(
              this.dynamicContent[i].template_html
            );
          }
          setTimeout(() => {
            this.removeEmptyElements();
          }, 0);
        }
        if (isPlatformBrowser(this.platformId)) {

          if (this.thankyouPageUrl.includes(this.slug)) {
            this.addConversionScript();
          } else {
            this.removeConversionScript();
          }

          if (this.slug === 'Home' || this.slug == '') {
            this.svg_Animation = true;
            setTimeout(() => {
              this.addBlogs(el.comapanyId);
            }, 10);
            setTimeout(() => {
              this.homeAnimation();
            }, 2000);
            this.homePageIndustriesAnimation();
            const video_scroll =
              this.elementRef.nativeElement.querySelector('#playVideo');
            if (video_scroll) {
              this.renderer.listen(video_scroll, 'click', () => {
                const videoSection =
                  this.elementRef.nativeElement.querySelector('#videoSection');
                this.svg_Animation;
                if (videoSection) {
                  videoSection.scrollIntoView({ behavior: 'smooth' });
                }
              });
            }
          } else if (this.animationRoutes.includes(this.slug)) {
            this.animationArray[this.animationIndex].function();
          }
          if (this.slug === 'careers') {
            this.getClearCoJobs();
            this.careerAnimation();
            this.copyClipboard();
            //Search Job
            const job_search =
              this.elementRef.nativeElement.querySelector('#search_result');
            if (job_search) {
              this.elementRef.nativeElement
                .querySelector('#search_result')
                .addEventListener('click', () => {
                  this.searchJob();
                });
            }
            //Reset Search box
            const reset_search =
              this.elementRef.nativeElement.querySelector('#reset_job');
            if (reset_search) {
              this.elementRef.nativeElement
                .querySelector('#reset_job')
                .addEventListener('click', () => {
                  let searchBox = document.getElementById(
                    'search_box'
                  ) as HTMLInputElement;
                  if (searchBox) {
                    searchBox.value = '';
                  }

                  let locationBox = document.getElementById(
                    'location_box'
                  ) as HTMLInputElement;
                  if (locationBox) {
                    locationBox.value = 'All Locations';
                  }

                  this.getClearCoJobs();
                });
            }
            const downloa_benefit = this.elementRef.nativeElement.querySelector(
              '#download_benefit_list'
            );
            if (downloa_benefit) {
              this.elementRef.nativeElement
                .querySelector('#download_benefit_list')
                .addEventListener('click', () => {
                  this.downloadPdf(environment.careers_Pdf);
                });
            }
            const accordionItems =
              this.elementRef.nativeElement.querySelectorAll('.accordionItem');
            if (accordionItems?.length > 0) {
              accordionItems.forEach((item: any) =>
                this.renderer.listen(item, 'click', () =>
                  this.openAccordion(item, accordionItems)
                )
              );
            }
            const goToJob =
              this.elementRef.nativeElement.querySelectorAll('#goToJobSection');
            if (goToJob?.length > 0) {
              goToJob.forEach((jobs: any) =>
                this.renderer.listen(jobs, 'click', () => this.goToJobs())
              );
            }
          } else if (
            this.slug === 'products/gift-loyalty' ||
            this.slug === 'products/hospitality-events'
          ) {
            this.dataGallery();
          } else if (this.slug === 'sitemap') {
            const download_art_spec =
              this.elementRef.nativeElement.querySelector('#dowload_artspec');
            if (download_art_spec) {
              this.elementRef.nativeElement
                .querySelector('#dowload_artspec')
                .addEventListener('click', () => {
                  this.downloadPdf(environment.submitArtwork_Pdf);
                });
            }
          } else if (this.slug === 'privacy_policy') {
            const video_scroll =
              this.elementRef.nativeElement.querySelector('#cookies');
            if (video_scroll) {
              this.renderer.listen(video_scroll, 'click', () => {
                const cookies_policy =
                  this.elementRef.nativeElement.querySelector('#cookie_policy');
                this.svg_Animation;
                if (cookies_policy) {
                  cookies_policy.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
              });
            }
            const privacy_pdf = this.elementRef.nativeElement.querySelector(
              '#privacy_policy_pdf'
            );
            if (privacy_pdf) {
              this.elementRef.nativeElement
                .querySelector('#privacy_policy_pdf')
                .addEventListener('click', () => {
                  this.downloadPdf(environment.privacy_policy);
                });
            }
          }
          let node = document.createElement('script');
          node.innerText =
            'setTimeout(() => {$(".carousel").carousel();}, 5000);';
          node.type = 'text/javascript';
          node.async = false;
          node.charset = 'utf-8';
          document.getElementsByTagName('body')[0].appendChild(node);
          // if(existCls)
          setTimeout(() => this.updateEvent(existCls), 1000);
        }
      },
      error: (error) => {
        this.homeNavigation('');
        console.log('error', error);
      },
    });
    setTimeout(() => {
      if (this.slug === 'contact_us') {
        //------old contact_us form--------------------------------
        if (environment.isLocalEnv === true) {
          console.log('LOCAL CHANGES');
          const element =
            this.elementRef.nativeElement.querySelector('#form_sub');
          if (element) {
            this.elementRef.nativeElement
              .querySelector('#form_sub')
              .addEventListener('click', () => this.contactUs());
          }
        } else {
          //--------------salesforce changes starts---------------------
          console.log('PRODUCTIOIN CHANGES');

          const contact_form =
            this.elementRef.nativeElement.querySelector('#contact_form');
          if (contact_form) {
            if (isPlatformBrowser(this.platformId)) {
              const description = document.getElementById(
                'description'
              ) as HTMLInputElement | null;
              if (description) {
                description.value = '';
                console.log(' description.value::: ', description.value);
              }
              const newContactForm =
                this.elementRef.nativeElement.querySelector('#newContactForm');

              //Creating the form input
              const form = document.createElement('form');
              form.action = environment.salesForceURL;
              form.method = 'POST';
              form.addEventListener('submit', (event) =>
                this.validateForm(event)
              );

              //Creating the orgid input
              const orgid = document.createElement('input');
              orgid.setAttribute('type', 'hidden');
              orgid.setAttribute('name', 'orgid');
              orgid.setAttribute('value', environment.Org_id);
              //Creating the returnURL input
              const retURL = document.createElement('input');
              retURL.setAttribute('type', 'hidden');
              retURL.setAttribute('name', 'retURL');
              retURL.setAttribute('value', environment.RetURL);
              // Getting firstname for concadination purposes
              if (document.getElementById('first_name') as HTMLInputElement) {
                this.elementRef.nativeElement
                  .querySelector('#first_name')
                  .addEventListener('change', (event: any) => {
                    this.firstName = event.target.value;
                    // console.log('First Name:', this.firstName);
                  });
              }
              // Getting lastname for concadination purposes
              if (document.getElementById('last_name') as HTMLInputElement) {
                this.elementRef.nativeElement
                  .querySelector('#last_name')
                  .addEventListener('change', (event: any) => {
                    this.LastName = event.target.value;
                    // console.log('Last Name:', this.LastName);
                  });
              }
              // Getting lastname for concadination purposes forn subject name
              if (document.getElementById('company') as HTMLInputElement) {
                this.elementRef.nativeElement
                  .querySelector('#company')
                  .addEventListener('change', (event: any) => {
                    this.company_Name = event.target.value;
                    console.log('this.company_Name::: ', this.company_Name);
                  });
              }
              // Getting lastname for concadination purposes forn subject name
              if (
                document.getElementById('how_can_wehelp') as HTMLInputElement
              ) {
                var value = document.getElementById(
                  'how_can_wehelp'
                ) as HTMLInputElement;
                if (value) {
                  value.value;
                  console.log('value.value::: ', value.value);
                }
                this.elementRef.nativeElement
                  .querySelector('#how_can_wehelp')
                  .addEventListener('change', (event: any) => {
                    this.HowCanWeHelp = event.target.value ?? value.value;
                    console.log('this.HowCanWeHelp::: ', this.HowCanWeHelp);
                  });
              }
              //Creating the Select input
              const selectElement = document.createElement('select');
              selectElement.id = 'recordType';
              selectElement.name = 'recordType';
              selectElement.style.display = 'none';
              //Creating the Select Option input
              const salesOption = document.createElement('option');
              salesOption.value = '0125f000000uXGq';
              salesOption.textContent = 'Sales';
              salesOption.selected = true;
              selectElement.appendChild(salesOption);
              //Append all the input fields to main div
              form.appendChild(selectElement);
              form.appendChild(orgid);
              form.appendChild(retURL);
              form.appendChild(contact_form);
              newContactForm.appendChild(form);
              const form_sub =
                this.elementRef.nativeElement.querySelector('#form_sub');
              if (form_sub) {
                this.elementRef.nativeElement
                  .querySelector('#form_sub')
                  .addEventListener('click', () => {
                    const additional_Fields =
                      this.elementRef.nativeElement.querySelectorAll(
                        '#additional_Fields'
                      );
                    if (additional_Fields?.length > 0) {
                      additional_Fields.forEach((item: any) => {
                        item.remove();
                      });
                    }

                    console.log(
                      'SUBJECT NAME',
                      `${this.HowCanWeHelp ?? value.value} - ${this.company_Name
                      }`
                    );
                    console.log(
                      'CONTACT NAME',
                      `${this.firstName} ${this.LastName}`
                    );
                    //append the contact name for salesforce
                    const contact_Name = document.createElement('input');
                    contact_Name.setAttribute('type', 'hidden');
                    contact_Name.setAttribute('name', 'name');
                    contact_Name.setAttribute('id', 'additional_Fields');
                    contact_Name.setAttribute(
                      'value',
                      `${this.firstName} ${this.LastName}`
                    );
                    form.appendChild(contact_Name);
                    //append the contact name for salesforce
                    const subject = document.createElement('input');
                    subject.setAttribute('type', 'hidden');
                    subject.setAttribute('name', 'subject');
                    subject.setAttribute('id', 'additional_Fields');
                    subject.setAttribute(
                      'value',
                      `${this.HowCanWeHelp ?? value.value} - ${this.company_Name
                      }`
                    );
                    form.appendChild(subject);
                    this.contactUs();
                  });
              }
            }
          }

          //----------------salesforce api ends here
        }
      } else if (this.slug === 'submit_artwork') {
        const download_art_spec =
          this.elementRef.nativeElement.querySelector('#dowload_artspec');
        if (download_art_spec) {
          this.elementRef.nativeElement
            .querySelector('#dowload_artspec')
            .addEventListener('click', () => {
              this.downloadPdf(environment.submitArtwork_Pdf);
            });
        }
        const element =
          this.elementRef.nativeElement.querySelector('#send_artwork');
        if (element) {
          this.elementRef.nativeElement
            .querySelector('#send_artwork')
            .addEventListener('click', () => this.sendArtwork());
        }

        const uploadFile =
          this.elementRef.nativeElement.querySelector('#upload_file');
        const labelElement =
          this.elementRef.nativeElement.querySelector('#fileUpload_label');
        const uploadYourArtworkElement =
          this.elementRef.nativeElement.querySelector('.uploadYourArtwork');
        //  console.log('uploadYourArtworkElement::: ', uploadYourArtworkElement.innerHTML);

        this.label_input = uploadYourArtworkElement.innerHTML;
        // console.log('label_input::: ', this.label_input);

        this.originalLabelContent = labelElement.innerHTML;
        if (uploadFile) {
          uploadFile.addEventListener('change', (event: any) => {
            this.onInputChange(event); // <- Error may occur here
          });
        } else {
          console.error('Input element with ID "yourInputId" not found.');
        }
      } else if (this.slug === 'sustainability') {
        const download_sustainability =
          this.elementRef.nativeElement.querySelector(
            '#download_sustainability'
          );
        if (download_sustainability) {
          this.elementRef.nativeElement
            .querySelector('#download_sustainability')
            .addEventListener('click', () => {
              this.downloadPdf(environment.sustainability_Pdf);
            });
        }
      } else {
        const element =
          this.elementRef.nativeElement.querySelector('#subscribe_sub');
        if (element) {
          this.elementRef.nativeElement
            .querySelector('#subscribe_sub')
            .addEventListener('click', () => this.subscription_submission());
        }
      }
    }, 3000);
  }



  // ngAfterViewInit(): void {
  //   if (isPlatformBrowser(this.platformId)) { // Check if it's running in a browser environment
  //     // Get the current URL
  //     const currentUrl = this.slug;
  //     // Check if the current URL matches the URL where you want to add the script
  //     if (currentUrl === 'submit-artwork/thank-you') {
  //       // Add the script dynamically if it's not present
  //       this.addConversionScript();

  //     } else {
  //       // Remove the script if it was previously added
  //       this.removeConversionScript();

  //     }

  //   }
  // }

  addConversionScript(): void {
    const script = this.renderer.createElement('script');
    script.text = `
      gtag('event', 'conversion_event_submit_lead_form', {
        // Include your event parameters here
      });
    `;
    script.setAttribute('id', 'conversionScript');
    this.renderer.appendChild(this.document.head, script);
  }


  removeConversionScript(): void {
    const script = this.document.getElementById('conversionScript');
    if (script) {
      this.renderer.removeChild(this.document.head, script);
    }
  }

  validationFunction(inputIds: any, formData: any) {
    let invalid = [];

    for (var i = 0; i < inputIds.length; i++) {
      var inputId = inputIds[i].name;
      var inputElement = document.getElementById(inputId) as HTMLInputElement;
      var inputValue = inputElement.value.trim();
      if (inputValue === '') {
        invalid.push(inputIds[i].id);
      } else {
        formData[inputId] = inputValue;
      }
    }

    return {
      invalid,
      formData,
    };
  }
  emailValidation(email: string) {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const isValid = emailRegex.test(email);
    return isValid
  }
  subscription_submission() {
    let api = `${environment.SendGridAPIUrl}/mail/subscription_submission`;

    let inputIds = [
      { name: 'full_name', id: 0 },
      { name: 'email', id: 1 },
      { name: 'company', id: 2 },
    ];

    let subscription_data: {
      [key: string]: string | boolean;
      full_name: string;
      email: string;
      company: string;
    } = {
      full_name: '',
      email: '',
      company: '',
    };

    let validation = this.validationFunction(inputIds, subscription_data);
    // console.log('validation::: ', validation);

    if (validation?.invalid.length > 1) {
      Swal.fire({
        text: `Please Fill All Required Field`,
        confirmButtonColor: '#080B36',
      });
      return;
    } else if (validation?.invalid.length === 1) {
      Swal.fire({
        text: `${validation?.invalid[0] === 0
          ? 'Full Name'
          : validation?.invalid[0] === 1
            ? 'Email'
            : 'Company Name'
          } is required`,
        confirmButtonColor: '#080B36',
      });
      return;
    } else {
      let emailvalidation = this.emailValidation(validation?.formData?.email)
      console.log('emailvalidation::: ', emailvalidation);

      if (emailvalidation === false) {
        Swal.fire({
          text: `Please enter the valid email`,
          confirmButtonColor: '#080B36',
        });
        return
      }

      let pli_Checkbox = document.getElementById(
        'receive_occasionalMarketing'
      ) as HTMLInputElement;
      let checkboxValue = pli_Checkbox?.checked;

      let params = {
        email: validation?.formData.email ?? '',
        fullname: validation?.formData.full_name ?? '',
        companyName: validation?.formData.company ?? '',
        checkboxValue: checkboxValue ? true : false,
      };

      this._service.post(api, params).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.router.navigate(['/subscription/thank-you']);
        }
      });
    }
  }

  uploadedFiles: { file: File; label: HTMLElement }[] = [];

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files;

    const initialFilesArray = [];

    if (files && files.length > 0) {
      const allowedExtensions = [
        'indd',
        'psd',
        'ai',
        'pdf',
        'eps',
        'tif',
        'tiff',
        'jpg',
        'jpeg',
        'png',
        'zip',
      ];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file && file.name) {
          const fileName: string = file.name;
          const fileExtension = fileName.split('.').pop()?.toLowerCase() || '';

          if (allowedExtensions.indexOf(fileExtension) === -1) {
          } else {
            const labelTag =
              this.elementRef.nativeElement.querySelector('#fileUpload_label');
            const pTag = labelTag.querySelector('p');
            // console.log('pTag::: ', pTag);
            if (pTag) {
              pTag.parentNode.removeChild(pTag);
            }

            const h5Tag = labelTag.querySelector('h5');
            if (h5Tag) {
              h5Tag.parentNode.removeChild(h5Tag);
            }

            const labelElement = this.createLabelElement(fileName);
            const removeButton = this.createRemoveButton(labelElement);

            this.uploadedFiles.push({ file, label: labelElement });
            initialFilesArray.push(file);
            // Append the label to the DOM
            const uploadYourArtworkElement =
              this.elementRef.nativeElement.querySelector('.uploadYourArtwork');
            this.renderer.appendChild(uploadYourArtworkElement, labelElement);
          }
        }
      }
    }
  }

  createRemoveButton(labelElement: HTMLElement): HTMLElement {
    const removeButton = this.renderer.createElement('button');
    const buttonText = this.renderer.createText('X');
    this.renderer.addClass(removeButton, 'removeUploadItem');
    this.renderer.appendChild(removeButton, buttonText);

    this.renderer.listen(removeButton, 'click', () => {
      this.onRemoveButtonClick(labelElement, event);
    });

    this.renderer.appendChild(labelElement, removeButton);

    return removeButton;
  }

  createLabelElement(fileName: string): HTMLElement {
    const newLabel = this.renderer.createElement('label');
    newLabel.setAttribute('id', 'upload_label_id');
    newLabel.setAttribute('class', 'uploadLableItem');
    const labelText = this.renderer.createText(fileName);

    this.renderer.appendChild(newLabel, labelText);
    return newLabel;
  }

  onRemoveButtonClick(labelElement: HTMLElement, event: any) {
    event.preventDefault();
    const index = this.uploadedFiles.findIndex(
      (item) => item.label === labelElement
    );
    // console.log('index::: ', index);

    if (index !== -1) {
      const { file, label } = this.uploadedFiles[index];

      console.log('Before Removal:', this.uploadedFiles);

      this.uploadedFiles.splice(index, 1);

      console.log('Removed File:', file);

      console.log('After Removal:', this.uploadedFiles);

      // console.log("beforee",event)

      console.log('UPLOAD FILES', this.uploadedFiles.length);

      if (this.uploadedFiles.length === 0) {
        const labelElement =
          this.elementRef.nativeElement.querySelector('#fileUpload_label');
        const value = this.label_input;
        console.log('value::: ', value);
        labelElement.innerHTML = value;
      }

      label.remove();
      const labelValue = this.getLabelValue(label);
    }
  }

  getLabelValue(labelElement: HTMLElement): string {
    // Use DOMParser to convert the HTML string to a DOM element
    const parser = new DOMParser();
    const doc = parser.parseFromString(labelElement.outerHTML, 'text/html');

    // Access the text content of the parsed element
    const parsedLabelElement = doc.body.firstChild as HTMLElement;
    return parsedLabelElement.textContent || '';
  }

  sendArtwork() {
    let api = `${environment.SendGridAPIUrl}/mail/creative_received`;

    let submitArtwork_data: {
      [key: string]: string | boolean;
      first_name: string;
      last_name: string;
      email: string;
      mobile: string;
      event_name: string;
      sale_representative: string;
      spl_instruction: string;
    } = {
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      event_name: '',
      sale_representative: '',
      spl_instruction: '',
    };

    let uploadFileValue = this.uploadedFiles || [];

    let inputIds = [
      { name: 'first_name', id: 0 },
      { name: 'last_name', id: 1 },
      { name: 'email', id: 2 },
      { name: 'mobile', id: 3 },
      { name: 'event_name', id: 4 },
      { name: 'sale_representative', id: 5 },
      { name: 'spl_instruction', id: 6 },
    ];

    let validation = this.validationFunction(inputIds, submitArtwork_data);

    if (validation?.invalid?.length > 1) {
      Swal.fire({
        text: `Please Fill All Required Field`,
        confirmButtonColor: '#080B36',
      });
      return;
    } else if (validation?.invalid?.length === 1) {
      Swal.fire({
        text: `${validation?.invalid[0] === 0
          ? 'First Name'
          : validation?.invalid[0] === 1
            ? 'Last Name'
            : validation?.invalid[0] === 2
              ? 'Email'
              : validation?.invalid[0] === 3
                ? 'Phone Number'
                : validation?.invalid[0] === 4
                  ? 'Event Name'
                  : validation?.invalid[0] === 5
                    ? 'Sales Representative'
                    : 'Special Instructions'
          } is required`,
        confirmButtonColor: '#080B36',
      });
      return;
    } else if (uploadFileValue.length === 0) {
      Swal.fire({
        text: `File Required`,
        confirmButtonColor: '#080B36',
      });
      return;
    } else {
      let emailvalidation = this.emailValidation(validation?.formData?.email)
      console.log('emailvalidation::: ', emailvalidation);

      if (emailvalidation === false) {
        Swal.fire({
          text: `Please enter the valid email`,
          confirmButtonColor: '#080B36',
        });
        return
      }
      let sale_representativeInput = document.getElementById(
        'sale_representative'
      ) as HTMLSelectElement;
      let sale_representativeText =
        sale_representativeInput.options[sale_representativeInput.selectedIndex]
          .text;

      let formData = new FormData();
      formData.append('email', validation?.formData?.email);
      formData.append('first_name', validation?.formData?.first_name);
      formData.append('last_name', validation?.formData?.last_name);
      formData.append('phone_number', validation?.formData?.mobile);
      formData.append(
        'sale_representative',
        validation?.formData?.sale_representative
      );
      formData.append('sale_rep_name', sale_representativeText);
      formData.append('splInstruction', validation?.formData?.spl_instruction);
      formData.append('event_name', validation?.formData?.event_name);

      for (let i = 0; i < uploadFileValue.length; i++) {
        let file = uploadFileValue[i];
        formData.append('files[]', file.file, file.file.name);
      }
      this._service.formPost(api, formData).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.router.navigate(['/submit-artwork/thank-you']);
        } else {
          Swal.fire({
            text: `${res?.message}`,
            confirmButtonColor: '#080B36',
          });
        }
      });
    }
  }

  validateForm(event: any) {
    console.log('event::: ', event.target.value);
    let inputIds = [
      { name: 'first_name', id: 0 },
      { name: 'last_name', id: 1 },
      { name: 'email', id: 2 },
      { name: 'phone', id: 3 },
      { name: 'company', id: 4 },
      { name: '00N5f00000WGsFV', id: 5 },
      { name: 'description', id: 6 },
      { name: 'how_can_wehelp', id: 7 },
    ];

    let invalid: any = [];

    let emailInput = document.getElementById('email') as HTMLInputElement;
    let emailValue = emailInput?.value;

    let occasionalMailYes = document.getElementById(
      'receiveEmail_yes'
    ) as HTMLInputElement;
    let occasionalMailNo = document.getElementById(
      'receiveEmail_No'
    ) as HTMLInputElement;

    if (occasionalMailNo.checked || occasionalMailYes.checked) {
      console.log('checked:::');
      // var occasionalMail = occasionalMailYes.checked === true ? 'Yes' : 'No'
    } else {
      this.isChecked = false;
    }

    for (let i = 0; i < inputIds.length; i++) {
      let inputId = inputIds[i].name;
      let inputElement = document.getElementById(inputId) as HTMLInputElement;
      let inputValue = inputElement.value.trim();

      if (inputValue === '') {
        invalid.push(inputIds[i].id);
      }
    }

    if (invalid.length > 1) {
      Swal.fire({
        text: `Please Fill All Required Field`,
        confirmButtonColor: '#080B36',
      });
      event.preventDefault();
    } else if (invalid.length === 1) {
      Swal.fire({
        text: `${invalid[0] === 0
          ? 'First Name'
          : invalid[0] === 1
            ? 'Last Name'
            : invalid[0] === 2
              ? 'Email'
              : invalid[0] === 3
                ? 'Phone Number'
                : invalid[0] === 4
                  ? 'Company Name'
                  : invalid[0] === 5
                    ? 'Business Segment'
                    : invalid[0] === 6
                      ? 'Description'
                      : 'How can we help'
          } is required`,
        confirmButtonColor: '#080B36',
      });
      event.preventDefault();
    } else if (invalid.length === 0 && this.isChecked === false) {
      this.isChecked = true;
      Swal.fire({
        text: `Please check any one option`,
        confirmButtonColor: '#080B36',
      });
      event.preventDefault();
    }
    let emailvalidation = this.emailValidation(emailValue)
    console.log('emailvalidation::: ', emailvalidation);
    if (emailvalidation === false) {
      Swal.fire({
        text: `Please enter the valid email`,
        confirmButtonColor: '#080B36',
      });
      event.preventDefault();
    }
  }

  contactUs() {
    let api = `${environment.SendGridAPIUrl}/mail/invite_email`;

    let contactForm_data: {
      [key: string]: string | boolean;
      first_name: string;
      last_name: string;
      email: string;
      phone: string;
      company: string;
      '00N5f00000WGsFV': string;
      description: string;
      how_can_wehelp: string;
    } = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      company: '',
      '00N5f00000WGsFV': '',
      description: '',
      how_can_wehelp: '',
    };

    let inputIds = [
      { name: 'first_name', id: 0 },
      { name: 'last_name', id: 1 },
      { name: 'email', id: 2 },
      { name: 'phone', id: 3 },
      { name: 'company', id: 4 },
      { name: '00N5f00000WGsFV', id: 5 },
      { name: 'description', id: 6 },
      { name: 'how_can_wehelp', id: 7 },
    ];

    let validation = this.validationFunction(inputIds, contactForm_data);
    // console.log('validation::: ', validation);

    if (validation?.invalid?.length > 1) {
      if (environment.isLocalEnv === true) {
        Swal.fire({
          text: `Please Fill All Required Field`,
          confirmButtonColor: '#080B36',
        });
        return;
      } else {
        return;
      }
    } else if (validation?.invalid?.length === 1) {
      if (environment.isLocalEnv === true) {
        Swal.fire({
          text: `${validation?.invalid[0] === 0
            ? 'First Name'
            : validation?.invalid[0] === 1
              ? 'Last Name'
              : validation?.invalid[0] === 2
                ? 'Email'
                : validation?.invalid[0] === 3
                  ? 'Phone Number'
                  : validation?.invalid[0] === 4
                    ? 'Company Name'
                    : validation?.invalid[0] === 5
                      ? 'Business Segment'
                      : validation?.invalid[0] === 6
                        ? 'Description'
                        : 'How can we help'
            } is required`,
          confirmButtonColor: '#080B36',
        });
        return;
      } else {
        return;
      }
    }

    let emailvalidation = this.emailValidation(validation?.formData?.email)
    console.log('emailvalidation::: ', emailvalidation);
    if (emailvalidation === false) {
      if (environment.isLocalEnv === true) {
        Swal.fire({
          text: `Please enter the valid email`,
          confirmButtonColor: '#080B36',
        });
        return;
      } else {
        return;
      }
    }

    let occasionalMailYes = document.getElementById(
      'receiveEmail_yes'
    ) as HTMLInputElement;
    let occasionalMailNo = document.getElementById(
      'receiveEmail_No'
    ) as HTMLInputElement;

    if (occasionalMailNo.checked || occasionalMailYes.checked) {
      var occasionalMail = occasionalMailYes.checked === true ? 'Yes' : 'No';
    } else {
      console.log('No option selected');
      Swal.fire({
        text: `Please check any one of the option`,
        confirmButtonColor: '#080B36',
      });
      return;
    }
    console.log('Form validated successfully');

    let params = {
      email: validation?.formData?.email ?? '',
      first_name: validation?.formData.first_name ?? '',
      last_name: validation?.formData.last_name ?? '',
      phone_number: validation?.formData.phone ?? '',
      company: validation?.formData.company ?? '',
      description: validation?.formData.description ?? '',
      segment: validation?.formData['00N5f00000WGsFV'] ?? '',
      how_can_we_help: validation?.formData.how_can_wehelp ?? '',
      occasionalMail: occasionalMail,
    };
    this._service.post(api, params).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.router.navigate(['/contact-us/thank-you']);
      }
    });
  }
  ngOnDestroy() {
    this.removeConversionScript();
    if (this.listenerFn) this.listenerFn();
    let lenTimeritem =
      this.elementRef.nativeElement.querySelectorAll('.timer-item');
    if (lenTimeritem.length > 0) this.subscription.unsubscribe();
  }
  updateEvent(scrollClassName: string = '') {
    let lenTimeritem =
      this.elementRef.nativeElement.querySelectorAll('.timer-item');
    if (lenTimeritem.length > 0)
      this.subscription = interval(1000).subscribe((x) => {
        this.getTimeDifference();
      });
    if (scrollClassName) {
      let positionElem = this.elementRef.nativeElement.querySelectorAll(
        `.${scrollClassName}`
      );
      positionElem[0].setAttribute('id', 'slider-container');
      var rect = positionElem[0].getBoundingClientRect();
      let y = rect.y; //+ 100;
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(rect.x, y);
      }
    }
  }
  getForm(uuid: any) {
    if (!uuid) return;
    let api = `${this.websiteUrl}/api/OptinForms?Id=${uuid.slice(1)}`;
    this._service.get(api).subscribe((form: any) => {
      this.optinFormData = form;
      this.openPopUp = true;
    });
  }

  submitOptinForm() {
    let api = `${this.emailUrl}/api/Contact/addOptinContact`;
    const emailInput = document.querySelector(
      "[type='email']"
    ) as HTMLInputElement;
    const emailValue = emailInput ? emailInput.value : null;
    const optinIdElement = document.querySelector(
      '[optin-id]'
    ) as HTMLInputElement;
    const optinIdValue = optinIdElement?.getAttribute('optin-id')
      ? optinIdElement.value
      : null;
    if (!emailValue && !optinIdValue) {
      return;
    }
    const firstname = document.getElementsByClassName(
      'firstname'
    )[0] as HTMLInputElement;
    const lastname = document.getElementsByClassName(
      'lastname'
    )[0] as HTMLInputElement;
    const phonenumber = document.getElementsByClassName(
      'phonenumber'
    )[0] as HTMLInputElement;
    const maillingAddress = document.getElementsByClassName(
      'maillingaddress'
    )[0] as HTMLInputElement;
    const regionvalue = document.getElementsByClassName(
      'regionvalue'
    )[0] as HTMLInputElement;
    let params = {
      phone_number: phonenumber ? phonenumber['value'] : null,
      id: 0,
      email: emailValue,
      company_id: this.domainInfo.company_info.company_id,
      parent_id: this.domainInfo.user_info.user_id,
      first_name: firstname ? firstname['value'] : null,
      last_name: lastname ? lastname['value'] : null,
      mailing_address: maillingAddress ? maillingAddress['value'] : null,
      region_id: 0,
      optinform_id: optinIdElement.value.slice(1),
      region_abbr: regionvalue ? regionvalue['value'] : '',
    };
    this._service.post(api, params).subscribe((form: any) => {
      this.openPopUp = false;
      Swal.fire(this.optinFormData.success_message);
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(OptinFormComponent, {
      width: '850px',
      data: { formData: this.optinFormData },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  navTo(route: any, menuClick: boolean = false) {
    route = route.split('~');
    window.scrollTo(0, 0);
    this._router.navigate(['/' + route[0]]);
    this.slug = route[0];

    if (route.length > 1) {
      this.getPageList(route[1]);
    } else this.getPageList();

    if (this._service.isDesktopDevice()) {
      this.mainDivhideShow = true;
    } else {
      this.mainDivhideShow = false;
    }
  }

  registerPlugin() {
    gsap.registerPlugin(
      ScrollTrigger,
      ScrollToPlugin,
      MotionPathPlugin,
      ScrollSmoother
    );
  }

  async sustainablityAnimation() {
    this.registerPlugin();
    await gsap.set('.swipe1', { opacity: 1 });
    await gsap.set('.swipe2', { opacity: 1 });
    await gsap.set('.swipe3', { opacity: 1 });
    const mm = gsap.matchMedia();

    mm.add('(min-width: 1027px)', () => {
      let windowWidth = window.innerWidth;
      const newAnimation = gsap.timeline();
      newAnimation.fromTo(
        '.sustainability_animation',
        {
          x: windowWidth + 300,
          opacity: 1,
          duration: 0.2,
        },
        {
          x: -200,
          y: 0,
          opacity: 1,
          duration: 1.5,
          onComplete: () => {
            swipingCard();
          },
        }
      );

      function swipingCard() {
        const cards = document.querySelectorAll('.sustainability_animation');
        const timeLine = gsap.timeline();
        timeLine
          .to(
            '.swipe1',
            {
              x: -205,
              y: 0,
              rotate: 90,
              duration: 1.5,
              opacity: 1,
            },
            0
          )
          .to(
            '.swipe2',
            {
              x: 65,
              y: -91,
              rotate: 90,
              duration: 1.5,
              opacity: 1,
            },
            0
          )
          .to(
            '.swipe3',
            {
              x: 65,
              y: 82,
              rotate: 90,
              // rotate: -7,
              duration: 1.5,
              opacity: 1,
            },
            0
          );
      }
    });
  }


  async fraudPreventionAnimation() {
    this.registerPlugin();
    let gcap = gsap.timeline()
    const mm = gsap.matchMedia();

    mm.add('(min-width: 1027px)', async () => {
      let windowWidth = window.innerWidth;
      const newAnimation = gsap.timeline();

      await gcap.from(".gcfp_animation", {
        x: 800,
        y: 0,
        rotate: 180,
        duration: 2,
      }, 0)

      // gsap.to(".gcfp_animation", {
      //   scrollTrigger: {
      //     trigger: '.interesting_Facts',
      //     scrub: 1,
      //   },
      //   rotate: 360,
      //   // x: 100,
      // })

      // const splitting_animation = document.querySelectorAll('.splitting_animation');
      // const splittingTimeLine = gsap.timeline();
      // splitting_animation.forEach((ele, idx) => {
      //   splittingTimeLine.to(ele, {
      //     x: idx % 2 == 0 ? -20 : 20,
      //     scrollTrigger: {
      //       trigger: ele,
      //       scrub: 1,
      //     },
      //   });
      // });

      // gsap

      newAnimation
        .fromTo(
          '.gcfpAni_one',
          {
            scrollTrigger: {
              trigger: '.gcfpAni_one',
              scrub: 1,
              end: '+=700',
            },
            x: -250,
            scale: 0.8,
            opacity: 0.8,
          },
          {
            scrollTrigger: {
              trigger: '.gcfpAni_one',
              scrub: 1,
              end: '+=700',
            },
            x: 0,
            scale: 1,
            opacity: 1,
          }
        )
        .fromTo(
          '.gcfpAni_four',
          {
            scrollTrigger: {
              trigger: '.gcfpAni_four',
              scrub: 1,
              end: '+=700',
            },
            x: 250,
            scale: 0.8,
            opacity: 0.8,
          },
          {
            scrollTrigger: {
              trigger: '.gcfpAni_four',
              scrub: 1,
              end: '+=700',
            },
            x: 0,
            scale: 1,
            opacity: 1,
          }
        ).fromTo(
          '.gcfpAni_five',
          {
            scrollTrigger: {
              trigger: '.gcfpAni_five',
              scrub: 1,
              end: '+=700',
            },
            x: -250,
            scale: 0.8,
            opacity: 0.8,
          },
          {
            scrollTrigger: {
              trigger: '.gcfpAni_five',
              scrub: 1,
              end: '+=700',
            },
            x: 0,
            scale: 1,
            opacity: 1,
          }
        ).fromTo(
          '.gcfpAni_eight',
          {
            scrollTrigger: {
              trigger: '.gcfpAni_eight',
              scrub: 1,
              end: '+=700',
            },
            x: 250,
            scale: 0.8,
            opacity: 0.8,
          },
          {
            scrollTrigger: {
              trigger: '.gcfpAni_eight',
              scrub: 1,
              end: '+=700',
            },
            x: 0,
            scale: 1,
            opacity: 1,
          }
        )
    });
  }

  async giftLoyaltyProductsAnimation() {
    await gsap.set('.swipe1', { opacity: 1 });
    await gsap.set('.swipe2', { opacity: 1 });
    await gsap.set('.swipe3', { opacity: 1 });
    await gsap.set('.swipe4', { opacity: 1 });
    await gsap.set('.swipe5', { opacity: 1 });
    await gsap.set('.swipe6', { opacity: 1 });
    //gsap plugin imports
    this.registerPlugin();

    //variable/timeline declaration
    let timeLine1 = gsap.timeline({ paused: false });
    timeLine1
      .fromTo(
        '#footer-scroll1',
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#footer-scroll2',
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      );

    const mm = gsap.matchMedia();

    mm.add('(min-width: 1027px)', () => {
      let windowWidth = window.innerWidth;
      const newAnimation = gsap.timeline();
      newAnimation.fromTo(
        '.indust_bnr_animation',
        {
          x: windowWidth + 300,
          opacity: 1,
          duration: 0.5,
          scale: 1,
        },
        {
          x: -80,
          y: 10,
          opacity: 1,
          duration: 1,
          scale: 0.6,
          onComplete: () => {
            swipingCard();
          },
        }
      );

      function swipingCard() {
        const cards = document.querySelectorAll('.indust_bnr_animation');
        const timeLine = gsap.timeline();
        timeLine
          .to(
            '.swipe3',
            {
              x: 0,
              y: -250,
              duration: 2.5,
              opacity: 1,
            },
            1
          )
          .to(
            '.swipe2',
            {
              y: -140,
              x: 350,
              duration: 2.5,
              opacity: 1,
            },
            1
          )
          .to(
            '.swipe4',
            {
              x: -350,
              y: -140,
              duration: 2.5,
              opacity: 1,
            },
            1
          )
          .to(
            '.swipe1',
            {
              x: 350,
              y: 100,
              duration: 2.5,
              opacity: 1,
            },
            1
          )
          .to(
            '.swipe5',
            {
              x: -350,
              y: 100,
              duration: 2.5,
              opacity: 1,
            },
            1
          )
          .to(
            '.swipe6',
            {
              opacity: 1,
            },
            0
          );
      }
    });
  }

  hospitalityEventsAnimation() {
    this.registerPlugin();

    let timeLine1 = gsap.timeline({ paused: false });

    let instustriesCard = document.querySelector('.industriesCard');
    let windowWidth = window.innerWidth;

    function swipingCard() {
      gsap.set('.hospitality_animation', { opacity: 1 });
      const cards = document.querySelectorAll('.loyalty_banner_animation');
      const timeLine = gsap.timeline();
      timeLine
        .fromTo(
          '.hospitality_one',
          {
            x: -475,
            y: 400,
            opacity: 0,
            scale: 0.7,
          },
          {
            x: -475,
            y: -100,
            // rotate: 40,
            duration: 2,
            opacity: 1,
            scale: 0.7,
          },
          0.5
        )
        .fromTo(
          '.hospitality_two',
          {
            x: 20,
            y: -400,
            rotate: 0,
            opacity: 0,
            scale: 0.7,
          },
          {
            x: 20,
            y: -160,
            // rotate: 20,
            duration: 2.5,
            opacity: 1,
            scale: 0.7,
          },
          1.5
        )
        .fromTo(
          '.hospitality_three',
          {
            x: 500,
            y: 10,
            rotate: 0,
            opacity: 1,
            scale: 0.8,
          },
          {
            x: -200,
            y: 10,
            duration: 3.5,
            opacity: 1,
            scale: 0.8,
          },
          0.3
        )
        .fromTo(
          '.hospitality_four',
          {
            x: 500,
            y: -175,
            rotate: 0,
            opacity: 1,
            scale: 0.8,
          },
          {
            x: -178,
            y: -175,
            duration: 3.5,
            opacity: 1,
            scale: 0.8,
          },
          0.5
        );
    }

    swipingCard();

    timeLine1
      .fromTo(
        instustriesCard,
        {
          x: windowWidth + 300,
          opacity: 1,
          duration: 0.2,
        },
        {
          x: 0,
          opacity: 1,
          duration: 0.2,
        }
      )
      .fromTo(
        '#footer-scroll1',
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#footer-scroll2',
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      );
    gsap.set('.industriesCard', {
      transformStyle: 'preserve-3d',
      transformPerspective: 1000,
    });

    const front = document.querySelector('#cardFront');
    const back = document.querySelector('#cardBack');
    gsap.set(back, { rotationY: -180 });

    const tl = gsap
      .timeline({ paused: true })
      .to(front, {
        duration: 1,
        rotationY: 180,
      })
      .to(
        back,
        {
          duration: 1,
          rotationY: 0,
        },
        0
      )
      .to('.industriesCard', { z: 0 }, 0)
      .to('.industriesCard', { z: 0 }, 0);
    var flag = true;

    let movingCard = document.querySelector('.industriesCard');

    movingCard?.addEventListener('mouseenter', function () {
      if (flag) {
        tl.play();
        flag = false;
      } else {
        tl.reverse();
        flag = true;
      }
    });
  }

  fulfillmentAnimation() {
    this.registerPlugin();
    const mm = gsap.matchMedia();
    mm.add('(min-width: 1027px)', () => {
      //variable/timeline declaration
      let timeLine1 = gsap.timeline({ paused: false });
      let windowWidth = window.innerWidth;

      timeLine1
        .fromTo(
          '#footer-scroll1',
          {
            y: -300,
            duration: 10,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
          },
          {
            y: -1000,
            duration: 10,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
          },
          0
        )
        .fromTo(
          '#footer-scroll2',
          {
            y: -1000,
            duration: 10,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
          },
          {
            y: -300,
            duration: 10,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
          },
          0
        );
      let noOfNode = this.elementRef.nativeElement.querySelector('.centerPath');
      let centreDiv = document.querySelectorAll('.VerticalStep');
      let endHeight = noOfNode.clientHeight;
      endHeight = endHeight < 2000 ? 2300 : endHeight;
      const newtime = gsap.timeline();

      newtime.fromTo(
        '#rollCard',
        {
          opacity: 1,
          scrollTrigger: {
            trigger: '.valueof',
            scrub: 0.7,
            end: '+=4100',
          },
          y: 0,
        },
        {
          opacity: 1,
          scrollTrigger: {
            trigger: '.valueof',
            scrub: 0.7,
            end: '+=4100',
          },
          y: endHeight,
        }
      );

      const centerTimeLine = gsap.timeline();
      centreDiv.forEach((ele, idx) => {
        centerTimeLine.to(ele, {
          x: idx % 2 == 0 ? -100 : 100,
          scrollTrigger: {
            trigger: ele,
            scrub: 1,
          },
        });
      });
    });
  }

  displayAnimation() {
    //gsap plugin imports
    this.registerPlugin();
    const mm = gsap.matchMedia();
    mm.add('(min-width: 1027px)', () => {
      //variable/timeline declaration
      let timeLine1 = gsap.timeline({ paused: false });

      let windowWidth = window.innerWidth;

      timeLine1
        .fromTo(
          '#footer-scroll1',
          {
            y: -300,
            duration: 10,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
          },
          {
            y: -1000,
            duration: 10,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
          },
          0
        )
        .fromTo(
          '#footer-scroll2',
          {
            y: -1000,
            duration: 10,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
          },
          {
            y: -300,
            duration: 10,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
          },
          0
        );

      let noOfNode = this.elementRef.nativeElement.querySelector('.centerPath');
      let centreDiv = document.querySelectorAll('.VerticalStep');
      let endHeight = noOfNode.clientHeight;
      const newtime = gsap.timeline();

      newtime.fromTo(
        '#rollCard',
        {
          opacity: 1,
          scrollTrigger: {
            trigger: '.valueof',
            scrub: 0.7,
            end: 4500,
          },
          y: 0,
        },
        {
          opacity: 1,
          scrollTrigger: {
            trigger: '.valueof',
            scrub: 0.7,
            end: 3800,
          },
          y: endHeight,
        }
      );

      const centerTimeLine = gsap.timeline();
      centreDiv.forEach((ele, idx) => {
        centerTimeLine.to(ele, {
          x: idx % 2 == 0 ? -100 : 100,
          scrollTrigger: {
            trigger: ele,
            scrub: 1,
          },
        });
      });
    });
  }

  packagingAnimation() {
    this.registerPlugin();
    let timeLine1 = gsap.timeline({ paused: false });
    timeLine1
      .fromTo(
        '#footer-scroll1',
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#footer-scroll2',
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      );

    let marqueTimeLine1 = gsap.timeline({ paused: false });
    let marqueTimeLine2 = gsap.timeline({ paused: false });
    let marque = document.querySelectorAll('.marquee');
    marque.forEach((marquee_tags, index) => {
      index == 0
        ? marqueTimeLine1.to(
          marquee_tags,
          {
            x: -800,
            duration: 14,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
            immediateRender: true,
          },
          0
        )
        : marqueTimeLine2.fromTo(
          marquee_tags,
          {
            x: -800,
            duration: 14,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
            immediateRender: true,
          },
          {
            x: 0,
            duration: 14,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
            immediateRender: true,
          },
          0
        );

      marquee_tags.addEventListener('mouseenter', function () {
        index == 0 ? marqueTimeLine1.pause() : marqueTimeLine2.pause();
      });

      marquee_tags.addEventListener('mouseleave', function () {
        index == 0 ? marqueTimeLine1.resume() : marqueTimeLine2.resume();
      });
    });

    const mm = gsap.matchMedia();
    mm.add('(min-width: 1027px)', () => {
      const splitting_animation = document.querySelectorAll(
        '.splitting_animation'
      );
      const splittingTimeLine = gsap.timeline();
      const cardAnimationTimeLine = gsap.timeline();
      const cardAnimationTimeLine2 = gsap.timeline();
      splitting_animation.forEach((ele, idx) => {
        splittingTimeLine.to(ele, {
          x: idx % 2 == 0 ? -20 : 20,
          scrollTrigger: {
            trigger: ele,
            scrub: 1,
          },
        });
      });

      const scale_animation = document.querySelectorAll('.scale_animation');
      scale_animation.forEach((ele, idx) => {
        splittingTimeLine.fromTo(
          ele,
          {
            y: -50,
            opacity: 1,
            scrollTrigger: {
              trigger: ele,
              scrub: 1,
            },
            stagger: 0.4,
            duration: 2,
          },
          {
            y: 0,
            opacity: 1,
            scaleY: 1,
            scale: 1,
            scrollTrigger: {
              trigger: ele,
              scrub: 1,
            },
            stagger: 0.4,
            duration: 2,
          },
          2
        );
      });

      let whiteCard = document.querySelector('.whiteCardDiv');
      function leftDiagnol() {
        cardAnimationTimeLine.fromTo(
          whiteCard,
          {
            opacity: 0.7,
            scale: 0.6,
            scrollTrigger: {
              trigger: '.diagonalCard',
              scrub: 0.7,
              end: 5500,
            },
          },
          {
            x: 2500,
            y: 1000,
            scrollTrigger: {
              trigger: '.diagonalCard',
              scrub: 0.7,
              end: 5500,
            },
          }
        );
      }
      leftDiagnol();

      cardAnimationTimeLine2
        .fromTo(
          '.leftCard',
          {
            scrollTrigger: {
              trigger: '.firstTriggerSection',
              scrub: 0.7,
              end: '+=600',
            },
            x: -250,
            scale: 0.8,
            opacity: 0.8,
          },
          {
            scrollTrigger: {
              trigger: '.firstTriggerSection',
              scrub: 0.7,
              end: '+=600',
            },
            x: 0,
            scale: 1,
            opacity: 1,
          }
        )
        .fromTo(
          '.rightCard',
          {
            scrollTrigger: {
              trigger: '.secondTriggerSection',
              scrub: 0.7,
              end: '+=600',
            },
            x: 250,
            scale: 0.8,
            opacity: 0.8,
          },
          {
            scrollTrigger: {
              trigger: '.secondTriggerSection',
              scrub: 0.7,
              end: '+=600',
            },
            x: 0,
            scale: 1,
            opacity: 1,
          }
        );
    });
  }

  cardPrintingAttributesAnimation() {
    //gsap plugin imports
    gsap.registerPlugin(
      ScrollTrigger,
      ScrollToPlugin,
      MotionPathPlugin,
      ScrollSmoother
    );

    let timeLine1 = gsap.timeline({ paused: false });
    timeLine1
      .fromTo(
        '#footer-scroll1',
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#footer-scroll2',
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      );

    const mm = gsap.matchMedia();
    mm.add('(min-width: 1027px)', () => {
      const splitting_animation = document.querySelectorAll(
        '.splitting_animation'
      );
      const splittingTimeLine = gsap.timeline();
      const cardAnimationTimeLine = gsap.timeline();
      const cardAnimationTimeLine2 = gsap.timeline();
      splitting_animation.forEach((ele, idx) => {
        splittingTimeLine.to(ele, {
          x: idx % 2 == 0 ? -20 : 20,
          scrollTrigger: {
            trigger: ele,
            scrub: 1,
          },
        });
      });

      const scale_animation = document.querySelectorAll('.scale_animation');
      scale_animation.forEach((ele, idx) => {
        splittingTimeLine.fromTo(
          ele,
          {
            y: -50,
            opacity: 1,
            scrollTrigger: {
              trigger: ele,
              scrub: 1,
            },
            stagger: 0.4,
            duration: 2,
          },
          {
            y: 0,
            opacity: 1,
            scaleY: 1,
            scale: 1,
            scrollTrigger: {
              trigger: ele,
              scrub: 1,
            },
            stagger: 0.4,
            duration: 2,
          },
          2
        );
      });

      let whiteCard = document.querySelector('.whiteCardDiv');
      let whiteCard2 = document.querySelector('.whiteCardDiv2');
      function leftDiagnol() {
        cardAnimationTimeLine.fromTo(
          whiteCard,
          {
            opacity: 0.7,
            scale: 0.6,
            scrollTrigger: {
              trigger: '.diagonalCard',
              scrub: 0.7,
              end: 5000,
            },
          },
          {
            x: 2000,
            y: 1200,
            scrollTrigger: {
              trigger: '.diagonalCard',
              scrub: 0.7,
              end: 5000,
            },
          }
        );
      }
      leftDiagnol();

      function rightDiagnol() {
        cardAnimationTimeLine2.fromTo(
          whiteCard2,
          {
            x: 1300,
            y: -400,
            opacity: 0.7,
            scale: 0.6,
            rotate: 90,
            scrollTrigger: {
              trigger: '.diagonalCard2',
              scrub: 0.7,
              end: 5000,
            },
          },
          {
            x: -500,
            y: 300,
            opacity: 0.5,
            scale: 0.6,
            rotate: 90,
            scrollTrigger: {
              trigger: '.diagonalCard2',
              scrub: 0.7,
              end: 4000,
            },
            // onReverseComplete:()=>leftDiagnol()
          }
        );
      }
      rightDiagnol();
    });
  }

  aboutUsAnimation() {
    this.registerPlugin();
    const mm = gsap.matchMedia();
    mm.add('(min-width: 1027px)', () => {
      let timeLine1 = gsap.timeline({ paused: false });

      let instustriesCard = document.querySelector('.industriesCard');
      let windowWidth = window.innerWidth;

      timeLine1
        .fromTo(
          instustriesCard,
          {
            x: windowWidth + 300,
            y: 50,
            scale: 0.7,
            opacity: 1,
            duration: 3,
          },
          {
            x: -120,
            y: 50,
            scale: 0.8,
            opacity: 1,
            duration: 3,
            onComplete: () => cardMover(),
          }
        )
        .fromTo(
          '#footer-scroll1',
          {
            y: -300,
            duration: 10,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
          },
          {
            y: -1000,
            duration: 10,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
          },
          0
        )
        .fromTo(
          '#footer-scroll2',
          {
            y: -1000,
            duration: 10,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
          },
          {
            y: -300,
            duration: 10,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
          },
          0
        );

      gsap.set('.industriesCard', {
        transformStyle: 'preserve-3d',
        transformPerspective: 1000,
      });

      const front = document.querySelector('#cardFront');
      const back = document.querySelector('#cardBack');
      gsap.set(back, { rotationY: -180 });

      const tl = gsap
        .timeline({ paused: true })
        .to(front, {
          duration: 1,
          rotationY: 180,
        })
        .to(
          back,
          {
            duration: 1,
            rotationY: 0,
          },
          0
        )
        .to('.industriesCard', { z: 0 }, 0)
        .to('.industriesCard', { z: 0 }, 0);
      var flag = true;

      let movingCard = document.querySelector('.industriesCard');

      movingCard?.addEventListener('mouseenter', function () {
        if (flag) {
          tl.play();
          flag = false;
        } else {
          tl.reverse();
          flag = true;
        }
      });

      const diag = gsap.timeline();
      const newDiag = gsap.timeline();
      let whiteCard = document.querySelector('.whiteCardDiv');

      function cardMover() {
        newDiag.fromTo(
          '.industriesCard',
          {
            // x:-3000,
            // y: 300,
            scrollTrigger: {
              trigger: '.missionVision',
              scrub: 0.7,
              end: 3000,
            },
          },
          {
            x: -1800,
            y: 800,
            scale: 1.2,
            rotate: -90,
            scrollTrigger: {
              trigger: '.missionVision',
              scrub: 0.7,
              end: 3000,
            },
          }
        );
      }

      diag
        .fromTo(
          '.mission_bg',
          {
            scrollTrigger: {
              trigger: '.missionVision',
              scrub: 0.7,
              end: '+=600',
            },
            x: -250,
          },
          {
            scrollTrigger: {
              trigger: '.missionVision',
              scrub: 0.7,
              end: '+=600',
            },
            x: 0,
          }
        )
        .fromTo(
          '.vision_bg',
          {
            scrollTrigger: {
              trigger: '.missionVision',
              scrub: 0.7,
              end: '+=600',
            },
            x: 250,
          },
          {
            scrollTrigger: {
              trigger: '.missionVision',
              scrub: 0.7,
              end: '+=600',
            },
            x: 0,
          }
        );

      diag.fromTo(
        whiteCard,
        {
          // x:-3000,
          // y: 300,
          scrollTrigger: {
            trigger: '.diagonalCard',
            scrub: 0.7,
            end: 5000,
          },
        },
        {
          x: 2000,
          y: 1200,
          scrollTrigger: {
            trigger: '.diagonalCard',
            scrub: 0.7,
            end: 5000,
          },
        }
      );
    });
  }

  locationAnimation() {
    this.registerPlugin();
    const mm = gsap.matchMedia();
    mm.add('(min-width: 1027px)', () => {
      let timeLine1 = gsap.timeline({ paused: false });
      timeLine1
        .fromTo(
          '.detailCard_1',
          {
            position: 'absolute',
            opacity: 0,
          },
          {
            position: 'static',
            opacity: 1,
          },
          0
        )
        .fromTo(
          '.detailCard_2',
          {
            position: 'absolute',
            opacity: 0,
          },
          {
            position: 'static',
            opacity: 1,
          },
          1
        )
        .fromTo(
          '.detailCard_3',
          {
            position: 'absolute',
            opacity: 0,
          },
          {
            position: 'static',
            opacity: 1,
            duration: 1,
          },
          2
        )
        .fromTo(
          '.detailCard_4',
          {
            position: 'absolute',
            opacity: 0,
          },
          {
            position: 'static',
            opacity: 1,
          },
          3
        )
        .fromTo(
          '.detailCard_5',
          {
            position: 'absolute',
            opacity: 0,
          },
          {
            position: 'static',
            opacity: 1,
          },
          4
        )
        .fromTo(
          '.detailCard_6',
          {
            position: 'absolute',
            opacity: 0,
          },
          {
            position: 'static',
            opacity: 1,
          },
          5
        );
    });
  }

  oneTeamOneDreamAnimation() {
    this.registerPlugin();
    const mm = gsap.matchMedia();
    mm.add('(min-width: 1027px)', () => {
      const timeLine1 = gsap.timeline();
      let instustriesCard = document.querySelector('.careerCard');
      let windowWidth = window.innerWidth;
      timeLine1.fromTo(
        instustriesCard,
        {
          x: windowWidth + 300,
          y: 115,
          opacity: 1,
          duration: 3,
          rotate: 90,
          scale: 1.6,
        },

        {
          x: -300,
          y: 115,
          opacity: 1,
          duration: 3,
          rotate: 90,
          scale: 1.6,
        }
      );

      gsap.set('.careerCard', {
        transformStyle: 'preserve-3d',
        transformPerspective: 1000,
      });

      const splitting_animation = document.querySelectorAll(
        '.splitting_animation'
      );

      splitting_animation.forEach((ele, idx) => {
        timeLine1.to(ele, {
          x: idx % 2 == 0 ? -90 : 90,
          scrollTrigger: {
            trigger: ele,
            start: 'top 100%',
            scrub: 1,
          },
        });
      });

      const front = document.querySelector('#cardFront');
      const back = document.querySelector('#cardBack');
      gsap.set(back, { rotationX: -180 });

      const tl = gsap
        .timeline({ paused: true })
        .to(front, {
          duration: 1,
          rotationX: 180,
        })
        .to(
          back,
          {
            duration: 1,
            rotationX: 0,
          },
          0
        )
        .to('.careerCard', { z: 0 }, 0)
        .to('.careerCard', { z: 0 }, 0);
      var flag = true;

      let movingCard = document.querySelector('.careerCard');

      movingCard?.addEventListener('mouseenter', function () {
        if (flag) {
          tl.play();
          flag = false;
        } else {
          tl.reverse();
          flag = true;
        }
      });
    });
  }

  careerAnimation() {
    this.registerPlugin();
    const mm = gsap.matchMedia();
    mm.add('(min-width: 1027px)', () => {
      const timeLine1 = gsap.timeline();
      const pliValues_rightAlign = document.querySelectorAll(
        '.pliValues_rightAlign'
      );
      const pliValues_leftAlign = document.querySelectorAll(
        '.pliValues_leftAlign'
      );
      const splitting_animation = document.querySelectorAll(
        '.splitting_animation'
      );
      let spit_animation_length =
        this.elementRef.nativeElement.querySelector('.apply_now').clientHeight +
        this.elementRef.nativeElement.querySelector('.benefits_sec')
          .clientHeight +
        this.elementRef.nativeElement.querySelector('.pli_values')
          .clientHeight +
        this.elementRef.nativeElement.querySelector('.joinUs_rectangle')
          .clientHeight;
      this.elementRef.nativeElement.querySelector('.career_lastpage')
        .clientHeight;

      const career_up_animation = document.querySelector(
        '.career_up_animation'
      );
      const newtime = gsap.timeline();

      newtime.fromTo(
        '.centercard_one',
        {
          opacity: 1,
          scrollTrigger: {
            trigger: '.benefits_sec',
            scrub: 0.8,
            end: 7200,
          },
          scale: 1,
          y: 0,
        },
        {
          opacity: 1,
          scrollTrigger: {
            trigger: '.benefits_sec',
            scrub: 0.8,
            end: '+=6800',
          },
          // immediateRender: true,
          scale: 0.7,
          y: spit_animation_length + 1400,
        },
        0
      );

      pliValues_rightAlign.forEach((ele, idx) => {
        timeLine1.fromTo(
          ele,
          {
            x: idx % 2 == 0 ? -100 : -50,
            scrollTrigger: {
              trigger: ele,
              scrub: 1,
              // stagger: 0.4
            },
          },
          {
            x: idx % 2 == 0 ? 100 : 50,
            scrollTrigger: {
              trigger: ele,
              scrub: 1,
              // stagger: 0.4
            },
          }
        );
      }, 0);

      pliValues_leftAlign.forEach((ele, idx) => {
        timeLine1.fromTo(
          ele,
          {
            x: idx % 2 == 0 ? 100 : 50,
            scrollTrigger: {
              trigger: '.pli_values ',
              scrub: 1,
              // stagger: 0.4
            },
          },
          {
            x: idx % 2 == 0 ? -100 : -50,
            scrollTrigger: {
              trigger: ele,
              scrub: 1,
              // stagger: 0.4
            },
          }
        );
      }, 0);
      const mm = gsap.matchMedia();
      mm.add('(min-width: 1027px)', () => {
        gsap.set(career_up_animation, { opacity: 1 });
        timeLine1.fromTo(
          career_up_animation,
          {
            y: 100,
            scrollTrigger: {
              trigger: '.joinUs_rectangle',
              scrub: 1,
              // stagger: 0.4
            },
          },
          {
            y: 0,
            scrollTrigger: {
              trigger: '.joinUs_rectangle',
              scrub: 1,
              // stagger: 0.4
            },
          },
          0
        );
      });

      splitting_animation.forEach((ele, idx) => {
        timeLine1.to(ele, {
          x: idx % 2 == 0 ? -50 : 50,
          scrollTrigger: {
            trigger: ele,
            start: 'top 100%',
            scrub: 1,
          },
        });
      }, 0);
    });
  }

  async industryGiftLoyalty() {
    this.registerPlugin();
    let timeLine1 = gsap.timeline({ paused: false });

    let windowWidth = window.innerWidth;

    timeLine1
      .fromTo(
        '#footer-scroll1',
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#footer-scroll2',
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      );

    const mm = gsap.matchMedia();

    mm.add('(min-width: 1027px)', async () => {
      await gsap.set('.swipe1', { opacity: 1 });
      await gsap.set('.swipe2', { opacity: 1 });
      await gsap.set('.swipe3', { opacity: 1 });
      await gsap.set('.swipe4', { opacity: 1 });
      await gsap.set('.swipe5', { opacity: 1 });
      await gsap.set('.swipe6', { opacity: 1 });
      let windowWidth = window.innerWidth;
      const newAnimation = gsap.timeline();
      newAnimation.fromTo(
        '.indust_bnr_animation',
        {
          x: windowWidth + 300,
          opacity: 1,
          duration: 1,
          scale: 0.5,
        },
        {
          x: -80,
          y: 50,
          opacity: 1,
          duration: 1.5,
          scale: 0.6,
          onComplete: () => {
            swipingCard();
          },
        }
      );

      function swipingCard() {
        const cards = document.querySelectorAll('.indust_bnr_animation');
        const timeLine = gsap.timeline();
        timeLine
          .to(
            '.swipe3',
            {
              x: 0,
              y: -250,
              duration: 2.5,
              opacity: 1,
            },
            1
          )
          .to(
            '.swipe2',
            {
              y: -140,
              x: 350,
              duration: 2.5,
              opacity: 1,
            },
            1
          )
          .to(
            '.swipe4',
            {
              x: -350,
              y: -140,
              duration: 2.5,
              opacity: 1,
            },
            1
          )
          .to(
            '.swipe1',
            {
              x: 350,
              y: 100,
              duration: 2.5,
              opacity: 1,
            },
            1
          )
          .to(
            '.swipe5',
            {
              x: -350,
              y: 100,
              duration: 2.5,
              opacity: 1,
            },
            1
          )
          .to(
            '.swipe6',
            {
              opacity: 1,
            },
            0
          );
      }

      const timeLine2 = gsap.timeline();

      document.querySelectorAll('.centerStroke').forEach((ele, idx) => {
        timeLine2.fromTo(
          ele,
          {
            opacity: 0,
            scrollTrigger: {
              // yPercent: -800,
              start: '+=200',
              trigger: ele,
              scrub: 1,
              // scaleY: 0
            },
          },
          {
            opacity: 1,
            scrollTrigger: {
              start: '0',
              // yPercent: 0,
              trigger: ele,
              scrub: 1,
              toggleClass: 'colorOrange',
              // scaleY: 1
            },

            // duration: 5
          }
        );
      });

      let noOfNode = document.querySelectorAll('.centerStroke');
      let centreDiv = document.querySelectorAll('.VerticalStep');
      let startHeight;
      let endHeight = 0;
      if (noOfNode.length) {
        startHeight = noOfNode[0].clientHeight;
        noOfNode.forEach((ele) => {
          endHeight += ele.clientHeight;
        });
        console.warn("END HEIGHT",endHeight)
        const newtime = gsap.timeline();

        newtime.fromTo(
          '#rollCard',
          {
            opacity: 1,
            scrollTrigger: {
              trigger: '.valueof',
              scrub: 1,
              end: 3000,
            },
            y: 0,
          },
          {
            opacity: 1,
            scrollTrigger: {
              trigger: '.valueof',
              scrub: 1,
              end: 4800,
            },
            y: 3400,
          }
        );

        const centerTimeLine = gsap.timeline();
        centreDiv.forEach((ele, idx) => {
          centerTimeLine.to(ele, {
            x: idx % 2 == 0 ? -140 : 140,
            scrollTrigger: {
              trigger: ele,
              scrub: 1,
            },
          });
        });
      }
    });
  }

  industryHospitality() {
    this.registerPlugin();

    //variable/timeline declaration
    let timeLine1 = gsap.timeline({ paused: false });

    let windowWidth = window.innerWidth;

    timeLine1
      .fromTo(
        '#footer-scroll1',
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#footer-scroll2',
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      );

    const mm = gsap.matchMedia();

    mm.add('(min-width: 1027px)', () => {
      function swipingCard() {
        gsap.set('.hospitality_animation', { opacity: 1 });
        const cards = document.querySelectorAll('.loyalty_banner_animation');
        const timeLine = gsap.timeline();
        timeLine
          .fromTo(
            '.hospitality_one',
            {
              x: -475,
              y: 400,
              opacity: 0,
              scale: 0.7,
            },
            {
              x: -475,
              y: -100,
              // rotate: 40,
              duration: 1.5,
              opacity: 1,
              scale: 0.7,
            },
            0.5
          )
          .fromTo(
            '.hospitality_two',
            {
              x: 20,
              y: -400,
              rotate: 0,
              opacity: 0,
              scale: 0.7,
            },
            {
              x: 20,
              y: -160,
              // rotate: 20,
              duration: 2,
              opacity: 1,
              scale: 0.7,
            },
            1.5
          )
          .fromTo(
            '.hospitality_three',
            {
              x: 500,
              y: 10,
              rotate: 0,
              opacity: 1,
              scale: 0.8,
            },
            {
              x: -200,
              y: 10,
              duration: 2.5,
              opacity: 1,
              scale: 0.8,
            },
            0.2
          )
          .fromTo(
            '.hospitality_four',
            {
              x: 500,
              y: -175,
              rotate: 0,
              opacity: 1,
              scale: 0.8,
            },
            {
              x: -178,
              y: -175,
              duration: 2.5,
              opacity: 1,
              scale: 0.8,
            },
            0.5
          );
      }

      swipingCard();

      const timeLine1 = gsap.timeline();

      document.querySelectorAll('.centerStroke').forEach((ele, idx) => {
        timeLine1.fromTo(
          ele,
          {
            opacity: 0,
            scrollTrigger: {
              // yPercent: -800,
              start: '+=200',
              trigger: ele,
              scrub: 1,
              // scaleY: 0
            },
          },
          {
            opacity: 1,
            scrollTrigger: {
              start: '0',
              // yPercent: 0,
              trigger: ele,
              scrub: 1,
              toggleClass: 'colorOrange',
              // scaleY: 1
            },
          }
        );
      });

      let noOfNode = document.querySelectorAll('.centerStroke');
      let centreDiv = document.querySelectorAll('.VerticalStep');
      let startHeight;
      let endHeight = 0;
      if (noOfNode.length) {
        startHeight = noOfNode[0].clientHeight;
        noOfNode.forEach((ele) => {
          endHeight += ele.clientHeight;
        });
        console.log("END HEIGHT", endHeight)
        const newtime = gsap.timeline();

        newtime.fromTo(
          '#rollCard',
          {
            opacity: 1,
            scrollTrigger: {
              trigger: '.valueof',
              scrub: 1,
            },
            y: 0,
          },
          {
            opacity: 1,
            scrollTrigger: {
              trigger: '.valueof',
              scrub: 1,
              end: 4800,
            },
            y: endHeight >= 3025 ? endHeight : 3025,
          }
        );

        const centerTimeLine = gsap.timeline();
        centreDiv.forEach((ele, idx) => {
          centerTimeLine.to(ele, {
            x: idx % 2 == 0 ? -140 : 140,
            scrollTrigger: {
              trigger: ele,
              scrub: 1,
            },
          });
        });
      }
    });
  }

  async industryEvents() {
    this.registerPlugin();
    await gsap.set('.swipe1', { opacity: 1 });
    await gsap.set('.swipe2', { opacity: 1 });
    await gsap.set('.swipe3', { opacity: 1 });
    await gsap.set('.swipe4', { opacity: 1 });
    let timeLine1 = gsap.timeline({ paused: false });

    // let instustriesCard = document.querySelector(".industriesCard");
    let windowWidth = window.innerWidth;

    timeLine1
      .fromTo(
        '#footer-scroll1',
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#footer-scroll2',
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      );

    let marqueTimeLine1 = gsap.timeline({ paused: false });
    let marqueTimeLine2 = gsap.timeline({ paused: false });

    let marque = document.querySelectorAll('.marquee');
    marque.forEach((marquee_tags, index) => {
      index == 0
        ? marqueTimeLine1.to(
          marquee_tags,
          {
            x: -800,
            duration: 14,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
            immediateRender: true,
          },
          0
        )
        : marqueTimeLine2.fromTo(
          marquee_tags,
          {
            x: -800,
            duration: 14,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
            immediateRender: true,
          },
          {
            x: 0,
            duration: 14,
            repeat: -1,
            ease: 'linear',
            yoyo: true,
            immediateRender: true,
          },
          0
        );

      marquee_tags.addEventListener('mouseenter', function () {
        index == 0 ? marqueTimeLine1.pause() : marqueTimeLine2.pause();
      });

      marquee_tags.addEventListener('mouseleave', function () {
        index == 0 ? marqueTimeLine1.resume() : marqueTimeLine2.resume();
      });
    });

    const mm = gsap.matchMedia();

    mm.add('(min-width: 1027px)', () => {
      let windowWidth = window.innerWidth;
      function swipingCard() {
        const cards = document.querySelectorAll('.indust_bnr_animation');
        const timeLine = gsap.timeline();
        timeLine
          .fromTo(
            '.swipe2',
            {
              x: windowWidth + 300,
              y: 150,
              rotate: 0,
              duration: 0.2,
              opacity: 0.5,
              scale: 0.8,
            },
            {
              x: -205,
              y: 150,
              rotate: 0,
              duration: 1.5,
              opacity: 1,
              scale: 0.8,
            },
            0.3
          )
          .fromTo(
            '.swipe4',
            {
              x: windowWidth + 300,
              y: 150,
              duration: 2,
              opacity: 1,
              scale: 0.8,
            },
            {
              x: 60,
              y: 150,
              duration: 2,
              opacity: 1,
              scale: 0.8,
            },
            0.5
          )
          .fromTo(
            '.swipe3',
            {
              x: -240,
              y: -1200,
              rotate: 0,
              opacity: 0,
              duration: 2.5,
              scale: 0.5,
            },
            {
              x: -240,
              y: -60,
              rotate: 0,
              opacity: 1,
              duration: 2.5,
              scale: 0.75,
            },
            0.7
          )
          .fromTo(
            '.swipe1',
            {
              x: windowWidth + 300,
              y: -60,
              duration: 3,
              opacity: 1,
              scale: 0.8,
            },
            {
              x: 40,
              y: -60,
              duration: 3,
              opacity: 1,
              scale: 0.8,
            },
            1
          );
      }
      swipingCard();

      function centerCardMoving() {
        const timeLine1 = gsap.timeline();
        document.querySelectorAll('.centerStroke').forEach((ele, idx) => {
          timeLine1.fromTo(
            ele,
            {
              opacity: 0,
              scrollTrigger: {
                // yPercent: -800,
                start: '+=200',
                trigger: ele,
                scrub: 1,
                // scaleY: 0
              },
            },
            {
              opacity: 1,
              scrollTrigger: {
                start: '0',
                // yPercent: 0,
                trigger: ele,
                scrub: 1,
                toggleClass: 'colorOrange',
                // scaleY: 1
              },

              // duration: 5
            }
          );
        });

        let noOfNode = document.querySelectorAll('.centerStroke');
        let centreDiv = document.querySelectorAll('.VerticalStep');
        let startHeight;
        let endHeight = 0;
        if (noOfNode.length) {
          startHeight = noOfNode[0].clientHeight;

          noOfNode.forEach((ele) => {
            endHeight += ele.clientHeight;
          });
          // console.warn(startHeight, noOfNode[0].scrollHeiht, noOfNode[0].offsetHeight);

          const newtime = gsap.timeline();

          newtime.fromTo(
            '#rollCard',
            {
              opacity: 1,
              scrollTrigger: {
                trigger: '.valueof',
                scrub: 1,
                end: endHeight,
              },
              y: 0,
            },
            {
              opacity: 1,
              scrollTrigger: {
                trigger: '.valueof',
                scrub: 1,
                end: endHeight * 1.7,
              },
              y: endHeight,
            }
          );

          const centerTimeLine = gsap.timeline();
          centreDiv.forEach((ele, idx) => {
            centerTimeLine.to(ele, {
              x: idx % 2 == 0 ? -140 : 140,
              scrollTrigger: {
                trigger: ele,
                scrub: 1,
              },
            });
          });
        }
      }
      centerCardMoving();
    });
  }

  async industryCasino() {
    this.registerPlugin();
    await gsap.set('.swipe1', { opacity: 1 });
    await gsap.set('.swipe2', { opacity: 1 });
    await gsap.set('.swipe3', { opacity: 1 });
    await gsap.set('.swipe4', { opacity: 1 });
    this.registerPlugin();

    let timeLine1 = gsap.timeline({ paused: false });
    let windowWidth = window.innerWidth;

    timeLine1
      .fromTo(
        '#footer-scroll1',
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#footer-scroll2',
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      );

    const mm = gsap.matchMedia();

    mm.add('(min-width: 1027px)', () => {
      let windowWidth = window.innerWidth;
      const newAnimation = gsap.timeline();

      function swipingCard() {
        const cards = document.querySelectorAll('.indust_bnr_animation');
        const timeLine = gsap.timeline();

        timeLine
          .fromTo(
            '.swipe4',
            {
              x: 0,
              y: 0,
              opacity: 0,
            },
            {
              x: -150,
              y: 100,
              opacity: 1,
            },
            1
          )
          .fromTo(
            '.swipe2',
            {
              x: 0,
              y: 0,
              scale: 0.3,
              opacity: 0,
            },
            {
              x: 130,
              y: 0,
              scale: 0.6,
              opacity: 1,
            },
            2
          )
          .fromTo(
            '.swipe3',
            {
              x: 0,
              y: 0,
              opacity: 0,
            },
            {
              x: -120,
              y: -100,
              opacity: 1,
            },
            1.5
          )
          .fromTo(
            '.swipe1',
            {
              x: 0,
              y: 0,
              rotate: -90,
              opacity: 0,
            },
            {
              x: -410,
              y: 0,
              rotate: -90,
              opacity: 1,
            },
            0
          );
      }
      swipingCard();

      function centerCardMoving() {
        gsap.set('.indust_bnr_animation', { scale: 0.75 });
        const timeLine1 = gsap.timeline();
        document.querySelectorAll('.centerStroke').forEach((ele, idx) => {
          timeLine1.fromTo(
            ele,
            {
              opacity: 0,
              scrollTrigger: {
                // yPercent: -800,
                start: '+=200',
                trigger: ele,
                scrub: 1,
                // scaleY: 0
              },
            },
            {
              opacity: 1,
              scrollTrigger: {
                start: '0',
                // yPercent: 0,
                trigger: ele,
                scrub: 1,
                toggleClass: 'colorOrange',
                // scaleY: 1
              },

              // duration: 5
            }
          );
        });

        let noOfNode = document.querySelectorAll('.centerStroke');
        let centreDiv = document.querySelectorAll('.VerticalStep');
        let startHeight;
        let endHeight = 0;
        if (noOfNode.length) {
          startHeight = noOfNode[0].clientHeight;
          noOfNode.forEach((ele) => {
            endHeight += ele.clientHeight;
          });
          const newtime = gsap.timeline();

          newtime.fromTo(
            '#rollCard',
            {
              opacity: 1,
              scrollTrigger: {
                trigger: '.valueof',
                scrub: 1,
              },
              y: 0,
            },
            {
              opacity: 1,
              scrollTrigger: {
                trigger: '.valueof',
                scrub: 1,
                end: endHeight * 1.8,
              },
              y: endHeight,
            }
          );

          const centerTimeLine = gsap.timeline();
          centreDiv.forEach((ele, idx) => {
            centerTimeLine.to(ele, {
              x: idx % 2 == 0 ? -140 : 140,
              scrollTrigger: {
                trigger: ele,
                scrub: 1,
              },
            });
          });
        }
      }
      centerCardMoving();
    });
  }

  async industryCruiseCharter() {
    await gsap.set('.swipe1', { opacity: 1 });
    await gsap.set('.swipe2', { opacity: 1 });
    await gsap.set('.swipe3', { opacity: 1 });
    await gsap.set('.swipe4', { opacity: 1 });
    this.registerPlugin();
    let timeLine1 = gsap.timeline({ paused: false });
    let windowWidth = window.innerWidth;

    timeLine1
      .fromTo(
        '#footer-scroll1',
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#footer-scroll2',
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      );

    const mm = gsap.matchMedia();

    mm.add('(min-width: 1027px)', () => {
      let windowWidth = window.innerWidth;
      const newAnimation = gsap.timeline();

      function swipingCard() {
        const cards = document.querySelectorAll('.indust_bnr_animation');
        const timeLine = gsap.timeline();

        timeLine
          .fromTo(
            '.swipe4',
            {
              x: 500,
              y: 500,
              rotate: 90,
              duration: 0.5,
              opacity: 0,
            },
            {
              x: 75,
              y: 125,
              rotate: 0,
              duration: 1,
              opacity: 1,
            },
            0
          )
          .fromTo(
            '.swipe2',
            {
              x: 500,
              y: -800,
              rotate: 180,
              duration: 1,
              opacity: 0,
            },
            {
              x: 25,
              y: -100,
              rotate: 0,
              duration: 1,
              opacity: 1,
              scale: 0.95,
            },
            0
          )
          .fromTo(
            '.swipe3',
            {
              x: -500,
              y: -800,
              rotate: 90,
              duration: 1,
              opacity: 0,
            },
            {
              x: -300,
              y: -100,
              rotate: 0,
              duration: 1,
              opacity: 1,
              scale: 0.95,
            },
            0
          )
          .fromTo(
            '.swipe1',
            {
              x: -1000,
              y: 800,
              rotate: 90,
              duration: 1,
              opacity: 0,
            },
            {
              x: -250,
              y: 120,
              rotate: 0,
              duration: 1,
              opacity: 1,
              scale: 0.95,
            },
            0
          );
      }

      swipingCard();

      function centerCardMoving() {
        const timeLine1 = gsap.timeline();
        document.querySelectorAll('.centerStroke').forEach((ele, idx) => {
          timeLine1.fromTo(
            ele,
            {
              opacity: 0,
              scrollTrigger: {
                // yPercent: -800,
                start: '+=200',
                trigger: ele,
                scrub: 1,
                // scaleY: 0
              },
            },
            {
              opacity: 1,
              scrollTrigger: {
                start: '0',
                // yPercent: 0,
                trigger: ele,
                scrub: 1,
                toggleClass: 'colorOrange',
                // scaleY: 1
              },

              // duration: 5
            }
          );
        });

        let noOfNode = document.querySelectorAll('.centerStroke');
        let centreDiv = document.querySelectorAll('.VerticalStep');
        let startHeight;
        let endHeight = 0;
        if (noOfNode.length) {
          startHeight = noOfNode[0].clientHeight;

          noOfNode.forEach((ele) => {
            endHeight += ele.clientHeight;
          });
          // console.warn(startHeight, noOfNode[0].scrollHeiht, noOfNode[0].offsetHeight);

          const newtime = gsap.timeline();

          newtime.fromTo(
            '#rollCard',
            {
              opacity: 1,
              scrollTrigger: {
                trigger: '.valueof',
                scrub: 1,
              },
              y: 0,
            },
            {
              opacity: 1,
              scrollTrigger: {
                trigger: '.valueof',
                scrub: 1,
                end: endHeight * 1.7,
              },
              y: endHeight,
            }
          );

          const centerTimeLine = gsap.timeline();
          centreDiv.forEach((ele, idx) => {
            centerTimeLine.to(ele, {
              x: idx % 2 == 0 ? -140 : 140,
              scrollTrigger: {
                trigger: ele,
                scrub: 1,
              },
            });
          });
        }
      }
      centerCardMoving();
    });
  }

  async industryEntertainment() {
    this.registerPlugin();
    await gsap.set('.swipe1', { opacity: 1 });
    await gsap.set('.swipe2', { opacity: 1 });
    await gsap.set('.swipe3', { opacity: 1 });
    await gsap.set('.swipe4', { opacity: 1 });
    let timeLine1 = gsap.timeline({ paused: false });
    let windowWidth = window.innerWidth;

    timeLine1
      .fromTo(
        '#footer-scroll1',
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#footer-scroll2',
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      );

    const mm = gsap.matchMedia();

    mm.add('(min-width: 1027px)', () => {
      function swipingCard() {
        const cards = document.querySelectorAll('.indust_bnr_animation');
        const timeLine = gsap.timeline();

        timeLine.fromTo(
          '.indust_bnr_animation',
          {
            x: -200,
            y: -800,
            duration: 0.5,
            opacity: 1,
            scale: 0,
          },
          {
            x: -200,
            y: 50,
            duration: 1,
            opacity: 1,
            scale: 0.8,
            onComplete: () => {
              timeLine.to('.card_2', {
                x: 0,
                y: -250,
                duration: 0.8,
                opacity: 1,
                onComplete: () => {
                  timeLine.to('.card_3', {
                    x: 350,
                    y: -250,
                    duration: 0.8,
                    opacity: 1,
                    onComplete: () => {
                      timeLine.to('.swipe4', {
                        x: 350,
                        y: 0,
                        duration: 0.8,
                        opacity: 1,
                      });
                    },
                  });
                },
              });
            },
          }
        );
      }
      swipingCard();

      let marqueTimeLine1 = gsap.timeline({ paused: false });
      let marqueTimeLine2 = gsap.timeline({ paused: false });

      let marque = document.querySelectorAll('.marquee');
      marque.forEach((marquee_tags, index) => {
        index == 0
          ? marqueTimeLine1.to(
            marquee_tags,
            {
              x: -800,
              duration: 14,
              repeat: -1,
              ease: 'linear',
              yoyo: true,
              immediateRender: true,
            },
            0
          )
          : marqueTimeLine2.fromTo(
            marquee_tags,
            {
              x: -800,
              duration: 14,
              repeat: -1,
              ease: 'linear',
              yoyo: true,
              immediateRender: true,
            },
            {
              x: 0,
              duration: 14,
              repeat: -1,
              ease: 'linear',
              yoyo: true,
              immediateRender: true,
            },
            0
          );

        marquee_tags.addEventListener('mouseenter', function () {
          index == 0 ? marqueTimeLine1.pause() : marqueTimeLine2.pause();
        });

        marquee_tags.addEventListener('mouseleave', function () {
          index == 0 ? marqueTimeLine1.resume() : marqueTimeLine2.resume();
        });
      });

      function centerCardMoving() {
        const timeLine1 = gsap.timeline();
        document.querySelectorAll('.centerStroke').forEach((ele, idx) => {
          timeLine1.fromTo(
            ele,
            {
              opacity: 0,
              scrollTrigger: {
                // yPercent: -800,
                start: '+=200',
                trigger: ele,
                scrub: 1,
                // scaleY: 0
              },
            },
            {
              opacity: 1,
              scrollTrigger: {
                start: '0',
                // yPercent: 0,
                trigger: ele,
                scrub: 1,
                toggleClass: 'colorOrange',
                // scaleY: 1
              },

              // duration: 5
            }
          );
        });

        let noOfNode = document.querySelectorAll('.centerStroke');
        let centreDiv = document.querySelectorAll('.VerticalStep');
        let startHeight;
        let endHeight = 0;
        if (noOfNode.length) {
          startHeight = noOfNode[0].clientHeight;

          noOfNode.forEach((ele) => {
            endHeight += ele.clientHeight;
          });

          const newtime = gsap.timeline();

          newtime.fromTo(
            '#rollCard',
            {
              opacity: 1,
              scrollTrigger: {
                trigger: '.valueof',
                scrub: 1,
              },
              y: 0,
            },
            {
              opacity: 1,
              scrollTrigger: {
                trigger: '.valueof',
                scrub: 1,
                end: endHeight * 1.7,
              },
              y: endHeight,
            }
          );

          const centerTimeLine = gsap.timeline();
          centreDiv.forEach((ele, idx) => {
            centerTimeLine.to(ele, {
              x: idx % 2 == 0 ? -140 : 140,
              scrollTrigger: {
                trigger: ele,
                scrub: 1,
              },
            });
          });
        }
      }
      centerCardMoving();
    });
  }

  async industryTransit() {
    this.registerPlugin();
    await gsap.set('.swipe3', { opacity: 0, scale: 0, y: 0, x: 0 });
    await gsap.set('.swipe4', { opacity: 0, scale: 0, y: 0, x: 0 });
    await gsap.set('.swipe1', { opacity: 0, scale: 0, y: 0, x: 0 });
    await gsap.set('.swipe2', { opacity: 0, scale: 0, y: 0, x: 0 });
    await gsap.set('.swipe5', { opacity: 1, scale: 0.54, y: -400, x: 0 });
    let timeLine1 = gsap.timeline({ paused: false });

    let windowWidth = window.innerWidth;

    timeLine1
      .fromTo(
        '#footer-scroll1',
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      )
      .fromTo(
        '#footer-scroll2',
        {
          y: -1000,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        {
          y: -300,
          duration: 10,
          repeat: -1,
          ease: 'linear',
          yoyo: true,
        },
        0
      );

    const mm = gsap.matchMedia();

    mm.add('(min-width: 1027px)', () => {
      let windowWidth = window.innerWidth;
      const newAnimation = gsap.timeline();
      newAnimation.fromTo(
        '.indust_bnr_animation',
        {
          x: -100,
          opacity: 1,
          duration: 0.5,
        },
        {
          x: -100,
          y: 0,
          opacity: 1,
          duration: 1,
          onComplete: () => {
            swipingCard();
          },
        }
      );

      function swipingCard() {
        const cards = document.querySelectorAll('.indust_bnr_animation');
        const timeLine = gsap.timeline();
        timeLine
          .to(
            '.swipe2',
            {
              y: -100,
              x: 215,
              duration: 2.5,
              opacity: 1,
              scale: 0.8,
            },
            0.2
          )
          .to(
            '.swipe4',
            {
              x: -215,
              y: -100,
              duration: 2.5,
              opacity: 1,
              scale: 0.8,
            },
            0.2
          )
          .to(
            '.swipe1',
            {
              x: 215,
              y: 100,
              duration: 2.5,
              opacity: 1,
              scale: 0.8,
            },
            0.2
          )
          .to(
            '.swipe3',
            {
              x: -215,
              y: 100,
              duration: 2.5,
              opacity: 1,
              scale: 0.8,
            },
            0.2
          )
          .to(
            '.swipe5',
            {
              opacity: 1,
            },
            0
          )
          .to(
            '.swipe5',
            {
              y: 0,
              duration: 0.9,
            },
            0
          );
      }
      swipingCard();

      function centerCardMoving() {
        const timeLine1 = gsap.timeline();
        document.querySelectorAll('.centerStroke').forEach((ele, idx) => {
          timeLine1.fromTo(
            ele,
            {
              opacity: 0,
              scrollTrigger: {
                // yPercent: -800,
                start: '+=200',
                trigger: ele,
                scrub: 1,
                // scaleY: 0
              },
            },
            {
              opacity: 1,
              scrollTrigger: {
                start: '0',
                // yPercent: 0,
                trigger: ele,
                scrub: 1,
                toggleClass: 'colorOrange',
                // scaleY: 1
              },

              // duration: 5
            }
          );
        });

        let noOfNode = document.querySelectorAll('.centerStroke');
        let centreDiv = document.querySelectorAll('.VerticalStep');
        let startHeight;
        let endHeight = 0;
        if (noOfNode.length) {
          startHeight = noOfNode[0].clientHeight;

          noOfNode.forEach((ele) => {
            endHeight += ele.clientHeight;
          });

          const newtime = gsap.timeline();

          newtime.fromTo(
            '#rollCard',
            {
              opacity: 1,
              scrollTrigger: {
                trigger: '.valueof',
                scrub: 1,
                end: endHeight,
              },
              y: 0,
            },
            {
              opacity: 1,
              scrollTrigger: {
                trigger: '.valueof',
                scrub: 1,
                end: endHeight * 2.5,
              },
              y: endHeight,
            }
          );

          const centerTimeLine = gsap.timeline();
          centreDiv.forEach((ele, idx) => {
            centerTimeLine.to(ele, {
              x: idx % 2 == 0 ? -140 : 140,
              scrollTrigger: {
                trigger: ele,
                scrub: 1,
              },
            });
          });
        }
      }
      centerCardMoving();
    });
  }

  formatDate(dateString: Date, type: string) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    if (type == 'date') {
      return `${day}`.padStart(2, '0');
    } else if (type == 'month') {
      return month;
    } else if ((type = 'year')) {
      return year;
    } else return null;

    // return `${day} ${month} ${year}`;
  }

  addBlogs(company_id: any) {
    let id = company_id;
    // let id = environment.companyId
    this.apiService.getAllBlogsHome(id).subscribe({
      next: (data: any) => {
        this.blogData = data?.blogs || [];
        const carousel =
          this.elementRef.nativeElement.querySelector('#owl_carousel');
        if (carousel) {
          carousel.classList.add(
            'owl-carousel',
            'owl-theme',
            'blog_owl',
            'margin_Bottom50'
          );

          for (let i = 0; i < this.blogData.length; i++) {
            const blogCard = this.renderer.createElement('div');
            this.renderer.addClass(blogCard, 'blogCard');
            this.renderer.listen(blogCard, 'click', () => {
              this._router.navigate([`card-talk/${this.blogData[i]?.slug}`]);
            });

            const blogCardFrame = this.renderer.createElement('div');
            this.renderer.addClass(blogCardFrame, 'blogCard_Frame');

            const blogCardImg = this.renderer.createElement('div');
            this.renderer.addClass(blogCardImg, 'blogCard_img');

            const blogDate = this.renderer.createElement('div');
            this.renderer.addClass(blogDate, 'blog-date');

            const h1 = this.renderer.createElement('h1');
            this.renderer.addClass(h1, 'font_Size24');
            this.renderer.addClass(h1, 'font_Weight500');
            this.renderer.addClass(h1, 'neroText');
            this.renderer.setProperty(
              h1,
              'innerText',
              this.formatDate(this.blogData[i]?.page?.created_at, 'date')
            );

            const spanMonth = this.renderer.createElement('span');
            this.renderer.addClass(spanMonth, 'font_Size14');
            this.renderer.addClass(spanMonth, 'font_Weight400');
            this.renderer.addClass(spanMonth, 'neroText');
            this.renderer.setProperty(
              spanMonth,
              'innerText',
              this.formatDate(this.blogData[i]?.page?.created_at, 'month')
            );

            const spanYear = this.renderer.createElement('span');
            this.renderer.addClass(spanYear, 'font_Size14');
            this.renderer.addClass(spanYear, 'font_Weight400');
            this.renderer.addClass(spanYear, 'neroText');
            this.renderer.addClass(spanYear, 'Display_inlineBlock');
            this.renderer.setProperty(
              spanYear,
              'innerText',
              this.formatDate(this.blogData[i]?.page?.created_at, 'year')
            );

            // Append elements to build the structure
            this.renderer.appendChild(blogDate, h1);
            this.renderer.appendChild(blogDate, spanMonth);
            this.renderer.appendChild(blogDate, spanYear);

            const img = this.renderer.createElement('img');
            this.renderer.setProperty(
              img,
              'src',
              this.blogData[i]?.page?.short_image || this.defaultIMG
            );
            this.renderer.setProperty(img, 'alt', this.blogData[i]?.blog_name);

            // Append elements to build the structure
            this.renderer.appendChild(blogCardImg, blogDate);
            this.renderer.appendChild(blogCardImg, img);

            const blogCardCaption = this.renderer.createElement('div');
            this.renderer.addClass(blogCardCaption, 'blogCard_caption');

            const div = this.renderer.createElement('div');

            const linkArrowImg = this.renderer.createElement('img');
            this.renderer.setProperty(
              linkArrowImg,
              'src',
              'https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/link_arrow.svg'
            );
            this.renderer.setProperty(linkArrowImg, 'alt', 'link_arrow');

            const h3 = this.renderer.createElement('h3');
            this.renderer.setProperty(
              h3,
              'innerText',
              this.blogData[i]?.page?.short_content
            );

            // Append elements to build the structure
            this.renderer.appendChild(div, linkArrowImg);
            this.renderer.appendChild(blogCardCaption, div);
            this.renderer.appendChild(blogCardCaption, h3);

            // Append elements to build the structure
            this.renderer.appendChild(blogCardFrame, blogCardImg);
            this.renderer.appendChild(blogCardFrame, blogCardCaption);

            // Append elements to build the structure
            this.renderer.appendChild(blogCard, blogCardFrame);

            // Append the blogCard to the carousel
            this.renderer.appendChild(carousel, blogCard);
          }

          setTimeout(() => {
            this.blogAnimation();
          }, 0);
        }
      },
      error: (error) => {
        console.log('error', error);
      },
    });
    this.getlatestnews(id);
  }

  openAccordion(item: any, accordionItems: any) {
    const isItemOpen = item.classList.contains('open');
    accordionItems.forEach((item: any) =>
      this.renderer.removeClass(item, 'open')
    );
    if (!isItemOpen) {
      this.renderer.addClass(item, 'open');
    }
  }

  goToJobs() {
    let focusElement = this.elementRef.nativeElement.querySelector(
      '#clearCo_jobOpening'
    );
    focusElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  searchJob() {
    let searchBox = document.getElementById('search_box') as HTMLInputElement;
    let searchBoxValue = searchBox?.value?.trim();

    let locationBox = document.getElementById(
      'location_box'
    ) as HTMLInputElement;
    let locationValue = locationBox?.value;
    locationValue =
      locationValue == 'All Locations' ? 'allLocation' : locationValue;
    let api = `${environment.SendGridAPIUrl}/clearco/findjobs?location=${locationValue}`;
    if (searchBoxValue != '') {
      api = `${environment.SendGridAPIUrl}/clearco/findjobs?location=${locationValue}&searchInputValue=${searchBoxValue}`;
    }
    this._service.get(api).subscribe({
      next: (response: any) => {
        this.clearCoJobs = response?.data;
        const element =
          this.elementRef.nativeElement.querySelector('#ClearCo_job_row');
        if (element) {
          element.innerHTML = '';
          if (this.clearCoJobs?.length === 0) {
            element.classList.remove('row');
            element.innerHTML = `<div class="font_Size18 font_Weight400 steelWoolText Display_Flex align_item_Center justify_content_Center height_100per">No such data found</div>`;
          } else {
            if (!element.classList.contains('row')) {
              element.classList.add('row');
            }
            for (const job of this.clearCoJobs) {
              const colDiv = this.renderer.createElement('div');
              this.renderer.addClass(colDiv, 'col-md-6');

              const jobItemDiv = this.renderer.createElement('div');
              this.renderer.addClass(jobItemDiv, 'jobItem');
              this.renderer.addClass(jobItemDiv, 'margin_Bottom24');

              const flexDiv = this.renderer.createElement('div');
              this.renderer.addClass(flexDiv, 'DeskFlex_Mobileblock');
              this.renderer.addClass(flexDiv, 'justify-content_Spacebetween');
              // this.renderer.addClass(flexDiv, 'margin_Bottom24');
              this.renderer.addClass(flexDiv, 'gap_16');

              const titleDiv = this.renderer.createElement('div');
              const titleH5 = this.renderer.createElement('h5');
              this.renderer.addClass(titleH5, 'font_Size18');
              this.renderer.addClass(titleH5, 'infiniteNightText');
              this.renderer.addClass(titleH5, 'font_Weight500');
              const titleText = this.renderer.createText(job?.JobTitle);

              const locationP = this.renderer.createElement('p');
              this.renderer.addClass(locationP, 'font_Size16');
              this.renderer.addClass(locationP, 'phaserBeamText');
              this.renderer.addClass(locationP, 'font_Weight500');
              const locationText = this.renderer.createText(
                `${job?.OfficeLocation?.City}, ${job?.OfficeLocation?.State}`
              );
              this.renderer.appendChild(locationP, locationText);

              const buttonDiv = this.renderer.createElement('div');
              const applyButton = this.renderer.createElement('button');
              this.renderer.addClass(applyButton, 'smallMainbutton');
              this.renderer.addClass(applyButton, 'infiniteNightBtn_clr');
              this.renderer.setAttribute(applyButton, 'data-toggle', 'modal');
              this.renderer.setAttribute(
                applyButton,
                'data-target',
                '#myModal'
              );
              const buttonText = this.renderer.createText('Apply');
              this.renderer.listen(buttonDiv, 'click', () =>
                this.showDetailDescription(
                  job?.JobDescription,
                  job?.ReqGuid,
                  job?.JobTitle
                )
              );
              this.renderer.appendChild(applyButton, buttonText);

              // const descriptionP = this.renderer.createElement('p');
              // this.renderer.addClass(descriptionP, 'steelWoolText');
              // this.renderer.addClass(descriptionP, 'font_Size16');
              // this.renderer.addClass(descriptionP, 'font_Weight400');
              // const descriptionText = this.renderer.createText('sample des');
              // this.renderer.appendChild(descriptionP, descriptionText);

              // Append elements
              this.renderer.appendChild(titleH5, titleText);
              this.renderer.appendChild(titleDiv, titleH5);
              this.renderer.appendChild(titleDiv, locationP);
              this.renderer.appendChild(flexDiv, titleDiv);
              this.renderer.appendChild(buttonDiv, applyButton);
              this.renderer.appendChild(flexDiv, buttonDiv);
              this.renderer.appendChild(jobItemDiv, flexDiv);
              // this.renderer.appendChild(jobItemDiv, descriptionP);
              this.renderer.appendChild(colDiv, jobItemDiv);

              this.renderer.appendChild(element, colDiv);
            }
          }
        }

        //   }
        // }
      },
      error: (err: any) => {
        console.log('err::: ', err);
      },
    });
  }



  // only for home page navigation
  homeNavigation(key: string) {
    this._router.navigate([`/${key}`]);
    this.slug = 'Home';
    // this.getPageList();
  }



  dataGallery() {
    $(document).ready(() => {
      $(this.elementRef.nativeElement)
        .find('.popup-link')
        .on('click', (e: Event) => {
          e.preventDefault();
          const $this = $(e.currentTarget);

          if ($this.data('gallery')) {
            let items: any[] = [];
            // If the clicked item has the data-gallery attribute, it's part of a group
            $('.popup-link[data-gallery="' + $this.data('gallery') + '"]').each(
              (index: any, element: any) => {
                const $element = $(element);
                const hrefValue = $element.attr('href');
                const titleValue = $element.attr('title');

                items.push({
                  src: hrefValue || '',
                  title: titleValue || '',
                });
              }
            );

            const currentIndex = $this.index();

            $.magnificPopup.open({
              items: items,
              type: 'image',
              gallery: {
                enabled: true,
              },
              callbacks: {
                buildControls: () => {
                  const $arrowRight = $('.mfp-arrow-right');
                  const $arrowLeft = $('.mfp-arrow-left');

                  $arrowRight.css(
                    'display',
                    currentIndex < items.length - 1 ? 'block' : 'none'
                  );
                  $arrowLeft.css(
                    'display',
                    currentIndex > 0 ? 'block' : 'none'
                  );
                },
              },
            });
          } else {
            // If the clicked item doesn't have the data-gallery attribute, it's a single image
            const src = $this.attr('href') || '';
            const title = $this.attr('title') || '';

            if (!src) {
              console.error('Image href is undefined for single item:', $this);
            }

            $.magnificPopup.open({
              items: {
                src: src,
                title: title,
              },
              type: 'image',
            });
          }
        });
    });
  }

  blogAnimation() {
    $('.blog_owl').owlCarousel({
      navText: [
        '<img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/left_arrow.svg">',
        '<img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/righarrow_icon.svg">',
      ],
      loop: true,
      margin: 10,
      nav: true,
      dots: true,
      center: true,
      autoplay: true,
      autoplayHoverPause: true,
      autoplaySpeed: 5000,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 4,
        },
      },
    });

    // counter number
    $('.counter_number').each(() => {
      $(this)
        .prop('Counter', 0)
        .animate(
          {
            Counter: $(this).text(),
          },
          {
            duration: 5000,
            easing: 'swing',
            step: (now: any) => {
              $(this).text(Math.ceil(now));
            },
          }
        );
    });
  }

  onSearchInput(event: any): void {
    const searchTerm = event.target.value;
    this.searchTerms.next(searchTerm);
  }

  onEnterKeyPress(): void {
    this.onSearch(this.currentSearchTerm);
  }

  onSearch(searchTerm: any) {
    if (isPlatformBrowser(this.platformId)) {
      let id =
        this.sessionStorageService.getItem('company_id') ??
        environment.companyId;
      // const inputValue = this.input.nativeElement.value.trim();
      if (searchTerm !== '') {
        // Add your API call logic here
        let api = `${this.websiteUrl}/api/Website/search?CompanyId=${id}&SearchFor=${searchTerm}`;
        this._service.get(api).subscribe((el: any) => {
          this.searchResults = el;
        });
      } else {
      }
    }
  }

  goToSearch(item: any) {
    if (item?.category == 'WEBSITE') {
      this.navTo(item.slug);
    } else if (item?.category === 'BLOG') {
      this.navTo(`card-talk/${item?.slug}`);
    } else if (item?.category === 'NEWS') {
      this.navTo(`news/detail/${item?.id}`);
    }
    const inputElement = document.getElementById(
      'search-input'
    ) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = '';
    }
    this.searchHandle();
    this.currentSearchTerm = '';

    this.searchResults = [];
  }

  private updateFavicon(href: string) {
    const head = document.head || document.getElementsByTagName('head')[0];
    const existingFavicon = document.getElementById('dynamic-favicon');

    // Remove the existing favicon if it exists
    if (existingFavicon) {
      head.removeChild(existingFavicon);
    }

    // Create a new link element for the favicon
    const link = document.createElement('link');
    link.id = 'dynamic-favicon';
    link.rel = 'icon';
    link.href = href;

    // Append the new link element to the head
    head.appendChild(link);
  }

  getClearCoJobs() {
    let api = `${environment.SendGridAPIUrl}/clearco/fetchjobs`;
    this._service.get(api).subscribe({
      next: (response: any) => {
        this.clearCoJobs = response?.response;
        const element =
          this.elementRef.nativeElement.querySelector('#ClearCo_job_row');
        if (element) {
          element.innerHTML = '';
          if (!element.classList.contains('row')) {
            element.classList.add('row');
          }
          for (const job of this.clearCoJobs) {
            const colDiv = this.renderer.createElement('div');
            this.renderer.addClass(colDiv, 'col-md-6');

            const jobItemDiv = this.renderer.createElement('div');
            this.renderer.addClass(jobItemDiv, 'jobItem');
            this.renderer.addClass(jobItemDiv, 'margin_Bottom24');

            const flexDiv = this.renderer.createElement('div');
            this.renderer.addClass(flexDiv, 'DeskFlex_Mobileblock');
            this.renderer.addClass(flexDiv, 'justify-content_Spacebetween');
            // this.renderer.addClass(flexDiv, 'margin_Bottom24');
            this.renderer.addClass(flexDiv, 'gap_16');

            const titleDiv = this.renderer.createElement('div');
            const titleH5 = this.renderer.createElement('h5');
            this.renderer.addClass(titleH5, 'font_Size18');
            this.renderer.addClass(titleH5, 'infiniteNightText');
            this.renderer.addClass(titleH5, 'font_Weight500');
            const titleText = this.renderer.createText(job?.JobTitle);

            const locationP = this.renderer.createElement('p');
            this.renderer.addClass(locationP, 'font_Size16');
            this.renderer.addClass(locationP, 'phaserBeamText');
            this.renderer.addClass(locationP, 'font_Weight500');
            const locationText = this.renderer.createText(
              `${job?.OfficeLocation?.City}, ${job?.OfficeLocation?.State}`
            );
            this.renderer.appendChild(locationP, locationText);

            const buttonDiv = this.renderer.createElement('div');
            const applyButton = this.renderer.createElement('button');
            this.renderer.addClass(applyButton, 'smallMainbutton');
            this.renderer.addClass(applyButton, 'infiniteNightBtn_clr');
            this.renderer.setAttribute(applyButton, 'data-toggle', 'modal');
            this.renderer.setAttribute(applyButton, 'data-target', '#myModal');
            const buttonText = this.renderer.createText('Apply');
            this.renderer.listen(buttonDiv, 'click', () =>
              this.showDetailDescription(
                job?.JobDescription,
                job?.ReqGuid,
                job?.JobTitle
              )
            );
            this.renderer.appendChild(applyButton, buttonText);

            // const descriptionP = this.renderer.createElement('p');
            // this.renderer.addClass(descriptionP, 'steelWoolText');
            // this.renderer.addClass(descriptionP, 'font_Size16');
            // this.renderer.addClass(descriptionP, 'font_Weight400');
            // const descriptionText = this.renderer.createText('sample des');
            // this.renderer.appendChild(descriptionP, descriptionText);

            // Append elements
            this.renderer.appendChild(titleH5, titleText);
            this.renderer.appendChild(titleDiv, titleH5);
            this.renderer.appendChild(titleDiv, locationP);
            this.renderer.appendChild(flexDiv, titleDiv);
            this.renderer.appendChild(buttonDiv, applyButton);
            this.renderer.appendChild(flexDiv, buttonDiv);
            this.renderer.appendChild(jobItemDiv, flexDiv);
            // this.renderer.appendChild(jobItemDiv, descriptionP);
            this.renderer.appendChild(colDiv, jobItemDiv);

            this.renderer.appendChild(element, colDiv);
          }
        }

        //   }
        // }
      },
      error: (err: any) => {
        console.log('err::: ', err);
      },
    });
  }

  showDetailDescription(jobDescription: any, id: any, jobTitle: any) {
    let list = document.getElementById('jobs_list');
    let parentDiv = document.getElementById('job_list_description');
    // if (list && parentDiv) {
    //   // list.style.display = 'none'
    //   this.renderer.setStyle(list, 'display', 'none');
    //   parentDiv.innerHTML = `<h2 class="font_Size32 infiniteNightText  font_Weight400 margin_Bottom8">${jobTitle}</h2>
    //   `
    //   parentDiv.innerHTML += jobDescription

    //   const element = this.elementRef.nativeElement.querySelector(
    //     '#job_list_description'
    //   );
    //   const backToList = this.renderer.createElement('button');
    //   const apply = this.renderer.createElement('button');

    //   this.renderer.listen(backToList, 'click', () => this.backToClearCoList());
    //   this.renderer.appendChild(
    //     backToList,
    //     this.renderer.createText('Back to Job Opening')
    //   );
    //   this.renderer.listen(apply, 'click', () => this.apply(id));
    //   this.renderer.addClass(backToList, 'smallMainbutton');
    //   this.renderer.addClass(backToList, 'infiniteNightBtn_clr');
    //   this.renderer.addClass(backToList, 'margin_Right12');
    //   this.renderer.addClass(apply, 'smallMainbutton');
    //   this.renderer.addClass(apply, 'infiniteNightBtn_clr');
    //   this.renderer.appendChild(apply, this.renderer.createText('Apply'));

    //   this.renderer.appendChild(element, backToList);
    //   this.renderer.appendChild(element, apply);

    //   let focusElement = this.elementRef.nativeElement.querySelector(
    //     '#clearCo_jobOpening'
    //   );
    //   focusElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }
    this.jobDescription =
      this.sanitizer.bypassSecurityTrustHtml(jobDescription);
    this.jobTitle = jobTitle;
    this.job_id = id;
  }

  backToClearCoList() {
    let list = this.elementRef.nativeElement.querySelector('#jobs_list');
    let list_description = this.elementRef.nativeElement.querySelector(
      '#job_list_description'
    );
    let focusElement = this.elementRef.nativeElement.querySelector(
      '#clearCo_jobOpening'
    );
    if (list && list_description) {
      list_description.innerHTML = '';
      // list.style.display = 'block'
      this.renderer.setStyle(list, 'display', 'block');

      focusElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  apply() {
    window.open(
      `https://plicards.clearcompany.com/careers/jobs/${this.job_id}/apply?source=CJB-0`,
      '_blank'
    );
  }

  copyClipboard() {
    let element =
      this.elementRef.nativeElement.querySelector('#copy_to_Clipboard');
    if (element) {
      this.renderer.listen(element, 'click', () => {
        navigator.clipboard.writeText("https://www.plicards.com/careers");
        Swal.fire({
          text: 'Link Copied!',
          timer: 500,
          showConfirmButton: false,
        });
      });
    }
  }
}
